/*添加亲属相关*/
/* keepAlive:该页面是否缓存
backHome:回到首页按钮是否显示
*/
export default [
    {
        path: 'relatives/index',
        name: 'relativesIndex',
        meta: {
            title: '我的亲属',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/index.vue')
    },
    {
        path: 'relatives/add',
        name: 'relativesAdd',
        meta: {
            title: '添加亲属',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/add.vue')
    },
    {
        path: 'relatives/list',
        name: 'relativesList',
        meta: {
            title: '我的福利',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/list.vue')
    },
    {
        path: 'relatives/shareList',
        name: 'relativesShareList',
        meta: {
            title: '福利分享',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/shareList.vue')
    },
    {
        path: 'relatives/receiveGive',
        name: 'relativesReceiveGive',
        meta: {
            title: '领取',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/receiveGive.vue')
    },
    {
        path: 'relatives/hisWelfare',
        name: 'hisWelfare',
        meta: {
            title: 'Ta的福利',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/hisWelfare.vue')
    },
    {
        path: 'relatives/viewDetails',
        name: 'viewDetails',
        meta: {
            title: '查看详情',
            keepAlive: false,
        },
        component: () => import('@/views/relatives/viewDetails.vue')
    },
    {
        path: 'relatives/tc',
        name: 'tc',
        meta: {
            title: 'tc',
        },
        component: () => import('@/views/relatives/tc.vue')
    }
]