/*资料相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    profileList: (data) => {//资料列表
        return axios.get('/products/repository/list', data);
    },
    profileSort: () => {//资料分类
        return axios.get('/products/repository/category');
    },
    profileDetails: (id,data) => {//资料详情
        return axios.get(`/products/repository/detail/${id}`,data);
    },
    fileAddress: (id,data) => {//资料地址
        return axios.get(`/products/repository/show/${id}`,data);
    },
    acquisitionPrice: (data) => {//资料到手价
        return axios.get(`/products/prices`, data);
    },
    repositoryDown: (id) => {//资料下载
        return axios.put(`/products/repository/down/${id}`);
    },
    deliveryList: (data) => {//活动交付区资料列表
        return axios.get('/products/activity/learn_area/repository/list', data);
    },
    progress: (id, date) => {//音视频进度
        return axios.put(`/products/repository/progress/${id}?progress=${date}`);
    },
    deliveryProgress: (data) => {//音视频交付区进度
        return axios.post(`/products/activity/learn_area/progress`,data);
    },
}