/*
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    columnSharePoster: (data) => {//获取海报
        return axios.get('/products/poster',data);
    },
    columnShare: (data) => {//获取文案
        return axios.get(`/api/tools/spread/doc/info`,data); 
    },
    columnHeadlines: (data) => {//获取头条
        return axios.get(`/api/tools/spread/news/info`,data); 
    },
    customShare: (data) => {//自定义分享接口
        return axios.get('/api/tools/spread/custom_share/info', data);
    },
}