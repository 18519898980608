/*新pk榜*/
/* keepAlive:该页面是否缓存
backHome:回到首页按钮是否显示
*/
export default [
    {
        path: 'pkList/list',
        name: 'pkListList',
        meta: {
            title:'PK榜',
            keepAlive: false,
        },
        component: () => import('@/views/pkList/list.vue')
    },
    {
        path: 'pkList/singleDetails',
        name: 'pkListSingleDetails',
        meta: {
            title:'PK榜单',
            keepAlive: false,
        },
        component: () => import('@/views/pkList/singleDetails.vue')
    },
    {
        path: 'pkList/teamDetails',
        name: 'pkListTeamDetails',
        meta: {
            title:'PK榜单',
            keepAlive: false,
        },
        component: () => import('@/views/pkList/teamDetails.vue')
    },
    {
        path: 'pkList/teamDetailsType',
        name: 'pkListteamDetailsType',
        meta: {
            title:'PK榜单',
            keepAlive: false,
        },
        component: () => import('@/views/pkList/teamDetailsType.vue')
    },
    {
        path: 'pkList/record',
        name: 'pkListRecord',
        meta: {
            title:'订单记录',
            keepAlive: false,
        },
        component: () => import('@/views/pkList/record.vue')
    },
]