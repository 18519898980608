/*每日心语
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    WordsDataApi: () => {//心语数据
        return axios.get('/api/api/carding');
    },
    WordsBgimgApi: (data) => {//心语数据
        return axios.get('/api/api/carding/img', data);
    },
    WordsCustomDaysApi: (data) => {//自定义打卡天数接口 
        return axios.post('/api/api/carding/share', data);
    },
    WordsVerseApi: () => {//金句
        return axios.get('/api/api/carding/word');
    },
    WordsCouponApi: (mechanismId) => {//心语优惠劵列表接口
        return axios.requestGet('/v1/ucentor/heart-words/coupon/' + mechanismId);
    },
    WordsCouponReceiveApi: (data) => {//心语优惠劵领取接口 
        return axios.post('/api/api/coupon/receive', data);
    },
    WordsStickerApi: () => {//贴纸接口
        return axios.get('/api/api/carding/stickers');
    },
    /**
     * 
     * 早安晚安重构接口
     *  */
    dailyConfig: () => {//获取早安晚安相关配置信息
        return axios.get('/api/tools/daily/config');
    },
    getBgs: (data) => {//获取背景图
        return axios.get('/api/tools/daily/img/list', data);
    },
    getWords: () => {//获取文案
        return axios.get('/api/tools/daily/word/list');
    },
    getCoupons: (data) => { // 获取优惠券
        return axios.post('/api/tools/daily/coupon/list', data);
    },
    setClockDay: (data) => {// 保存用户输入的打卡天数
        return axios.post('/api/tools/daily/task/save/num', data);
    },
    doClock: () => {// 打卡
        return axios.post('/api/tools/daily/task/submit');
    },
}