/*学习进度
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    columns: (data) => axios.get('/api/api/center/courses/columns/categories?version=1', data),//我的课程-专栏-分类
    activities: (data) => axios.get('/api/api/center/courses/activities/categories', data),//我的课程-活动-分类
    tasks: (data) => axios.get('/api/api/center/courses/tasks/categories', data),//我的课程-打卡-分类
    lives: (data) => axios.get('/api/api/center/courses/lives/categories', data),//我的课程-直播-分类
    appoints: (data) => axios.get('/api/api/center/courses/appoints/categories', data),//我的课程-约见-分类
    trainingCampPoints: (data) => axios.get('/api/api/center/courses/camps/categories', data),//我的课程-训练营分类
    columnProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/columns`, data),//学习进度-专栏 
    videoProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/videos`, data),//学习进度-视频 
    activityProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/activities`, data),//学习进度-活动和训练营 
    clockProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/tasks`, data),//学习进度-打卡 
    meetProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/appoints`, data),//学习进度-约见  
    liveProgress: (data, userId) => axios.get(`/api/api/center/progress/${userId}/lives`, data),//学习进度-直播 
    trainingCamp: (data, userId) => axios.get(`/api/api/center/progress/${userId}/camps`, data),//学习进度-训练营
}