/*赠票使用
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getCodeList: (data) => {//获取兑换码列表
        return axios.get('/api/api/agent/give/code',data);
    },
    getInfo: (data) => {//获取基本信息-领取码信息-产品信息
        return axios.get('/api/api/agent/give/code/product',data);
    },
    postCreateCode: (data) => {//生成兑换码
        return axios.post('/api/api/agent/give/code',data);
    },
    postReceiveCode: (data) => {//生成兑换码
        return axios.post('/api/api/agent/give/code/receive',data);
    },
    getOtherUserInfo: (userId) => {//获取他人用户信息
        return axios.get('/api/api/user/baseinfo?user_id='+userId,);
    },
    getGiveList: (data) => {//获取兑换码使用详情的列表
        return axios.get('/api/api/agent/give/code/receive/list',data);
    },
    getReceiveList: (data) => {//获取兑换码使用详情的列表
        return axios.get('/api/api/agent/give/code/receive/list',data);
    },
    getSellList: (data) => {//获取兑换码使用详情的列表
        return axios.get('/api/api/agent_give_product/sell_product_detail_list',data);
    },
    postCostOrder: (data) => {//赠票成本下单
        return axios.post('/api/api/agent/give/code/pay/payment',data);
    },
    getSaleInfo: (data) => {//卖给好友数据
        return axios.get('/products/agent/give/info',data);
    },
}