/*积分
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    userIntegral: () => axios.get('/api/api/integral/v1/user'),//获取积分用户信息
    integralDetails: (data) => axios.get('/api/api/integral/v1/detail', data),//明细列表
    integralRanking: () => axios.get('/api/api/integral/v1/ranking'),//积分排名
    taskEarning: () => axios.get('/api/api/integral/v1/currency'),//任务赚积分
    integralTab: () => axios.get('/api/api/integral/v1/tab'),//获取tab
    checkEarning: () => axios.post('/api/api/integral/v1/sign-in'),//签到获取积分
    earnPoints: () => axios.get('/api/api/integral/v1/learning'),//获取赚积分
    sellProducts: (type) => axios.get(`/api/api/integral/v1/${type}/product`),//获取买/卖产品 购买产品：buy，卖出产品：sell
    otherEarningPoints: () => axios.get('/api/api/integral/v1/other'),//获取其他赚积分
    integralRules: (groupId) => axios.get(`/api/api/integral/config/${groupId}`),//用户积分使用规则
}

