/* 商城相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'shopping/index',
        name: 'shoppingIndex',
        meta: {
            title: '商城',
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
            // isShowTab: false
        },
        component: () => import('@/views/shopping/index.vue')
    },
    {
        path: 'shopping/newIndex',
        name: 'shoppingNewIndex',
        meta: {
            title: '商城',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/newIndex.vue')
    },
    {
        path: 'shopping/shoppingList',
        name: 'shoppingList',
        meta: {
            title: '商城',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/shoppingList.vue')
    },
    {
        path: 'shopping/saleZoneList',
        name: 'saleZoneList',
        meta: {
            title: '商城',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/saleZoneList.vue')
    },
    {
        path: 'shopping/search',
        name: 'search',
        meta: {
            title: '搜索',
            keepAlive: false,
            isLoading: true,
            isShowTab: false
        },
        component: () => import('@/views/shopping/search.vue')
    },
    {
        path: 'shopping/details',
        name: 'shoppingDetails',
        meta: {
            title: '商城详情',
            // keepAlive:true,
            // backSource:'', //用于判断上一个页面是哪个
            // isShowTab: false
        },
        component: () => import('@/views/shopping/details.vue')
    },
    {
        path: 'shopping/cart',
        name: 'shoppingCart',
        meta: {
            title: '购物车',
            // isShowTab: false 
        },
        component: () => import('@/views/shopping/cart.vue')
    },
    {
        path: 'shopping/shoppingOrder',
        name: 'shoppingOrder',
        meta: {
            title: '商城订单',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/shoppingOrder.vue')
    },
    {
        path: 'shopping/orderPay',
        name: 'shoppingorderPay',
        meta: {
            title: '确认订单',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/orderPay.vue')
    },
    {
        path: 'shopping/addressList',
        name: 'shoppingaddressList',
        meta: {
            title: '收货地址',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/addressList.vue')
    },
    {
        path: 'shopping/address',
        name: 'shoppingaddress',
        meta: {
            title: '地址',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/address.vue')
    },
    {
        path: 'shopping/logistics',
        name: 'shoppinglogistics',
        meta: {
            title: '物流详情',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/logistics.vue')
    },
    {//商品物流
        path: 'shopping/logisticslist',
        name: 'shoppinglogisticslist',
        meta: {
            title: '物流详情',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/logisticslist.vue')
    },
    {//海报
        path: 'shopping/poster',
        name: 'shoppingposter',
        meta: {
            title: '商城海报',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/poster.vue')
    },
    {
        path: 'shopping/shoppingEquity',
        name: 'shoppingEquity',
        meta: {
            title: '权益卡',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/shoppingEquity.vue')
    },
    {
        path: 'shopping/shoppingEquityList',
        name: 'shoppingEquityList',
        meta: {
            title: '商品列表',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/shoppingEquityList.vue')
    },
    {
        path: 'shopping/shoppingSelf',
        name: 'shoppingSelf',
        meta: {
            title: '选择自提点',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/shoppingSelf.vue')
    },
    {//商城地址列表
        path: 'shopping/addressLists',
        name: 'shoppingaddressLists',
        meta: {
            title: '收货地址',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/addressLists.vue')
    },
    {//商城退款申请
        path: 'shopping/refundApplication',
        name: 'shoppingrefundApplication',
        meta: {
            title: '申请退款',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/refundApplication.vue')
    },
    {//商城退款申请更新
        path: 'shopping/refundApplicatioUpdaten',
        name: 'shoppingrefundApplicatioUpdaten',
        meta: {
            title: '申请退款',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/refundApplicatioUpdaten.vue')
    },
    {//商城填写物流单号
        path: 'shopping/fillLogisticsNumbers',
        name: 'shoppinfillLogisticsNumbers',
        meta: {
            title: '填写物流单号',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/fillLogisticsNumbers.vue')
    },
    {//商城退款售后详情
        path: 'shopping/serviceDetailed',
        name: 'shoppinserviceDetailed',
        meta: {
            title: '售后详情',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/serviceDetailed.vue')
    },
    {
        path: 'shopping/drawbackLogistics',
        name: 'shoppingdrawbackLogistics',
        meta: {
            title: '物流详情',
            // isShowTab: false
        },
        component: () => import('@/views/shopping/drawbackLogistics.vue')
    },
]