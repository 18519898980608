/*海报推广相关*/
/* keepAlive:该页面是否缓存
backHome:回到首页按钮是否显示
*/
export default [
    {
        path: 'postersPromoting/index',
        name: 'postersPromotingIndex',
        meta: {
            title: '海报',
            keepAlive: false,
        },
        component: () => import('@/views/postersPromoting/index.vue')
    },
]