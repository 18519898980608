/*商城首页装修
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {

    // 商城装修
    getShoppingModules: (data, type) => {//商城首页装修模块接口
        if(!!type){
            return axios.get('/api/api/home/decorates/'+ type, data);
        }else{
            return axios.get('/api/api/home/decorates', data);
        }
    },
    getProductsList: (component_type, data) => {//获取模块列表
        return axios.post('/api/api/home/decorates/items/'+ component_type, data);
    },

    













    refundListApi: (data) => {//退款列表
        return axios.get('/api/api/account/detail/refund/list', data);
    },
    getInvoiceCreate: (data) => {//发票保存接口
        return axios.post('/api/api/invoice/create',data);
    },
    getInvoicePayableDel: (itemId) => {//删除发票抬头
        return axios.iDelete('/api/api/invoice/payable-del/'+itemId);
    },
    putAccountCancel: (accountId) => {//钱包详情页-撤销
        return axios.put('api/api/account/detail/cancel/'+accountId);
    },
    videoTags: () => {//标签列表，无分页
        return axios.get('/api/tools/tags/list/all?type=video_promotion');
    },
}