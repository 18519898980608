/* 专栏 */
/* keepAlive:该页面是否缓存 */
export default [
  {
    path: 'specialColumn/index',
    name: 'specialColumn',
    meta: {
      title: '专栏详情',
      keepAlive: false,
    },
    component: () => import('@/views/specialColumn/index.vue')
  },
  {
    path: 'specialColumn/index2',
    name: 'specialColumnX',
    meta: {
      title: '专栏详情',
      keepAlive: false,
    },
    component: () => import('@/views/specialColumn/index2.vue')
  },
  {
    path: 'specialColumn/courseware',
    name: 'courseware',
    meta: {
      title: '课件详情',
      keepAlive: false,
    },
    component: () => import('@/views/specialColumn/courseware.vue')
  }
]