import noAuthority from '@/components/noAuthority/noAuthority.vue'
let noAuthorityObj = {}
noAuthorityObj.install = function (Vue) {
    let instance;
    // 在Vue的原型上扩展方法
    Vue.showAuthority = Vue.prototype.$showAuthority = function () {
        // 实例化所引入的插件
        const ModalLoadController = Vue.extend(noAuthority)
        // 实例化插件并挂在到某一个元素上
        instance = new ModalLoadController({
            el: document.createElement('div')
        });
        // 添加在body内
        document.body.appendChild(instance.$el)
    };
}
export default noAuthorityObj