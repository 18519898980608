/* 服务商相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {//我服务的销售
        path: 'serviceProvider/salesList',
        name: 'salesList',
        meta: {
            title: '我服务的销售',
        },
        component: () => import('@/views/serviceProvider/salesList.vue')
    },
    {//搜索我服务的销售
        path: 'serviceProvider/serviceSearch',
        name: 'serviceSearch',
        meta: {
            title: '搜索',
            keepAlive: true
        },
        component: () => import('@/views/serviceProvider/serviceSearch.vue')
    },
    {//我服务的销售
        path: 'serviceProvider/details',
        name: 'serviceProviderDetails',
        meta: {
            title: '服务金',
        },
        component: () => import('@/views/serviceProvider/details.vue')
    },
    {//每个月的服务金明细
        path: 'serviceProvider/monthlyService',
        name: 'monthlyService',
        meta: {
            title: '',
        },
        component: () => import('@/views/serviceProvider/monthlyService.vue')
    },
    {//结算记录
        path: 'serviceProvider/settlementRecord',
        name: 'settlementRecord',
        meta: {
            title: '结算记录',
        },
        component: () => import('@/views/serviceProvider/settlementRecord.vue')
    },
     {//结算明细
        path: 'serviceProvider/withdrawalContent',
        name: 'withdrawalContent',
        meta: {
            title: '结算记录',
        },
        component: () => import('@/views/serviceProvider/withdrawalContent.vue')
    },
     {//结算
        path: 'serviceProvider/withdraw',
        name: 'withdraw',
        meta: {
            title: '提现',
        },
        component: () => import('@/views/serviceProvider/withdraw.vue')
    },
    {//服务商协议签署
        path: 'serviceProvider/maleCardFree',
        name: 'serviceProviderMaleCardFree',
        meta: {
            title: '服务商协议签署',
        },
        component: () => import('@/views/serviceProvider/maleCardFree.vue')
    },
     {//订单明细
        path: 'serviceProvider/order',
        name: 'serviceProviderOrder',
        meta: {
            title: '订单明细',
        },
        component: () => import('@/views/serviceProvider/order.vue')
    },
     {//详情
        path: 'serviceProvider/orderDetail',
        name: 'serviceProviderorderDetail',
        meta: {
            title: '详情',
        },
        component: () => import('@/views/serviceProvider/orderDetail.vue')
    },
    {//订单明细搜索
        path: 'serviceProvider/search',
        name: 'serviceProviderSearch',
        meta: {
            title: '订单明细',
        },
        component: () => import('@/views/serviceProvider/search.vue')
    },
]