/*协议相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    agreemenIndexApi: (contentId) => {//协议内容
        return axios.get('/api/api/protocols/contents/'+contentId);
    },
    agreementListApi: (data) => {//列表
        // return axios.get('/api/api/users/'+uid+'/protocols', data);
        return axios.get('/api/api/protocol/v2/user/protocol/list',data);
    },
    agreemenIndexContentApi: (data) => {//协议列表内容
        return axios.get('/api/api/protocol/v2/content/detail',data);
    },
    agreementOtherApi: (data) => {//其他协议
        return axios.get('/api/api/protocol/v2/other/info', data);
    },
    agreementAllApi: (data) => {//普通产品:获取协议标题
        return axios.get('/api/api/protocol/v2/product/info', data);
    },
    agreementAgentApi: (data) => {// 合伙人:获取协议信息
        return axios.get('/api/api/protocol/v2/proxy/info', data);
    },
    agreementContentApi: (data) => {// 协议内容
        return axios.get('/api/api/protocol/v2/content/info',data);
    },
    agreementSigningApi: (data) => {// 协议签约
        return axios.post('/api/api/protocol/v2/sign',data);
    },
    agreementContractApi: (data) => {// 电子协议是否签约成功
        return axios.get('/api/api/protocol/v2/user/sign',data);
    },
    replenishment: (data) => {//是否需要补签电子协议
        return axios.get('/api/api/protocol/v6/special_product/saled/if_sign',data);
    },
}