import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)
const specialColumn = {
  namespaced:true,
  state: {
    signData: null,//他的福利- 报名信息
  },

  mutations: {
    changeSignData: (state, datas) => {
      state.signData = datas;
    }
  },

  getters: {
    getSignData: (state) => {
      return state.signData
    }
  },

  actions: {
    setSignData: (context, data) => {
      context.commit('changeSignData', data);
    }
  }
}
export default specialColumn
