/* 资料相关 */
/* keepAlive:该页面是否缓存 */
export default [
  {
    path: 'form/receiveList',
    name: 'formReceiveList',
    meta: {
      title: '领取奖励',
    },
    component: () => import('@/views/form/receiveList.vue')
  },
  {
    path: 'form/result',
    name: 'formResult',
    meta: {
      title: '评分结果',
    },
    component: () => import('@/views/form/result.vue')
  },
  {
    path: 'form/record',
    name: 'formRecord',
    meta: {
      title: '提交记录',
    },
    component: () => import('@/views/form/record.vue')
  },
  {
    path: 'form/recommend',
    name: 'formRecommend',
    meta: {
      title: '电子表单关联产品',
    },
    component: () => import('@/views/form/recommend.vue')
  },
  {
    path: 'form/flexList',
    name: 'formFlexList',
    meta: {
      title: '关联产品列表',
    },
    component: () => import('@/views/form/flexList.vue')
  },
  {
    path: 'form/advertisement',
    name: 'formAdvertisement',
    meta: {
      title: '购买成功',
    },
    component: () => import('@/views/form/advertisement.vue')
  },
  {
    path: 'form/index',
    name: 'formIndex',
    meta: {
      title: '',
    },
    component: () => import('@/views/form/index.vue')
  },
  {
    path: 'form/music',
    name: 'formMusic',
    meta: {
      title: '测试音频播放功能',
    },
    component: () => import('@/views/form/music.vue')
  },
  {
    path: 'form/categoryList',
    name: 'categoryList',
    meta: {
      title: '',
    },
    component: () => import('@/views/form/categoryList.vue')
  },
  {
    path: 'form/searchTitle',
    name: 'searchTitle',
    meta: {
      title: '',
    },
    component: () => import('@/views/form/searchTitle.vue')
  },
]