/* 账户相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'news/newsList',
        name: 'newsList',
        meta: {
            title: '头条列表',
            // isShowTab: false
        },
        component: () => import('@/views/news/newsList.vue')
    },
    {
        path: 'news/detail',
        name: 'newsDetail',
        meta: {
            title: '头条详情',
        },
        component: () => import('@/views/news/detail.vue')
    },
    
]