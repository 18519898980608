/*我的团队相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getList: (data) => axios.get('/api/api/request_log', data),//获取访客记录列表


    getLogs: (data) => axios.requestGet('/v1/ucentor/request-logs/view', data),//获取用户访客详情
}