/* demo 有七牛视频播放器、原生录音 */
/* keepAlive:该页面是否缓存 */
export default [
    {//七牛视频播放器
        path: 'demo/player',
        name: 'demoPlayer',
        meta: {
            title: '视频播放器demo1',
        },
        component: () => import('@/views/demo/player.vue')
    },
    {//原生录音-userMedia
        path: 'demo/usermedia',
        name: 'demouserMedia',
        meta: {
            title: '录音',
        },
        component: () => import('@/views/demo/usermedia.vue')
    },
    {//原生录音-userMedia
        path: 'demo/mp3rec',
        name: 'mp3rec',
        meta: {
            title: '录音-mp3rec',
        },
        component: () => import('@/views/demo/mp3rec.vue')
    },
     {//原生录音-userMedia
        path: 'demo/audio',
        name: 'audio',
        meta: {
            title: '录音-audio',
        },
        component: () => import('@/views/demo/audio.vue')
    },
    {//设备信息
        path: 'demo/device',
        name: 'device',
        meta: {
            title: '设备信息device',
        },
        component: () => import('@/views/demo/device.vue')
    },
]