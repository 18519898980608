/** 钱包相关 */
export default [
    {
        path: 'wallet/topUp',
        name: 'topUp',
        meta: {
            title: '充值',
            isShowTab: false,
            isLoading: true
        },
        component: () => import('@/views/wallet/topUp.vue')
    },
    {
        path: 'wallet/topUpSuccess',
        name: 'topUpSuccess',
        meta: {
            title: '充值',
            isShowTab: false,
            isLoading: true
        },
        component: () => import('@/views/wallet/topUpSuccess.vue')
    },

]