/*海报相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    // platformPosters: (groupId, data) => axios.requestGet(`/v1/posters/mechanism/${groupId}`, data),//平台海报
    // activityPosters: (id, data) => axios.requestGet(`/v1/actives/get-poster/${id}`, data),//活动海报
    partnerPosters: (id) => axios.get(`/api/api/poster_center/agent_poster/${id}`),//合伙人海报
    // vipPosters: (groupId, data) => axios.requestGet(`/v1/posters/partner/${groupId}/16`, data),//vip海报老接口
    sVipPosters: () => axios.get(`api/api/mechanism_vip/posters`),//sVip海报
    basicSettings: () => axios.get(`api/api/mechanism_vip/basic_settings`),//sVip默认海报 
    agentPosters: (product_type, sub_product_id, type, product_id, page) => axios.get(`/products/poster?product_type=${product_type}&sub_product_id=${sub_product_id}&type=${type}&product_id=${product_id}&page=${page}`),//合伙人分享素材(海报)
    agentMaterial: (agentId) => axios.get(`/api/api/posters/agent/${agentId}`),//合伙人分享素材
    publicCustomShare: (data) => axios.get(`/api/api/custom-share`,data),//公共的自定义分享接口
    //海报新接口（vip, 活动,平台）
    postersApi: (product_type, sub_product_id, type, product_id, page) => axios.get(`/products/poster?product_type=${product_type}&sub_product_id=${sub_product_id}&type=${type}&product_id=${product_id}&page=${page}`),
}