/*我的客户
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getCustomerList: (data) => {//列表
        return axios.get('/api/api/myself-customer/index',data);
    },
    getCustomerTar: () => {//筛选标签
        return axios.get('/api/api/mechanism/tag/index');
    },
    getCustomerInfoTar: (data) => {//用户标签提交接口
        return axios.post('/api/api/mechanism/tag/set-user-tag',data);
    },
    getCustomerConsultant: (data) => {//金牌客户
        return axios.get('/api/api/agent/helper/user-list',data);
    },
    getCustomerHelperList: (data) => {//求助列表退回记录
        return axios.get('/api/api/agent/helper/index',data);
    },
    getCustomerHelper: () => {//求助比例和时间接口
        return axios.get('/api/api/agent/helper/config');
    },
    getCustomerHelperDetails: (data) => {//成交详情
        return axios.get('/api/api/agent/helper/user-orders',data);
    },
    getCustomerRecord: (data) => {//跟进记录列表
        // return axios.get('/api/api/conversion/record',data);
        return axios.get('/api/api/conversion/records',data);
    },
    getCustomerRecordSub: (data) => {//跟进记录添加
        return axios.post('/api/api/conversion/record',data);
    },
    getCustomerinventoryList: (data) => {//确认清单
        return axios.get('/api/api/myself-customer/user-info',data);
    },
    getCustomerSubInventory: (data) => {//提交求助
        return axios.post('/api/api/myself-customer/transfer',data);
    },
    getRateTypes: () => {//获取产品类型
        return axios.get('/products/common/types');
    },
    getSearchList: (data) => {//搜索标题
        return axios.get('/products/common/search',data);
    },
    getConfirmScreen: (data) => {//搜索产品
        return axios.get('/api/api/delivery/product_count',data);
    },
    getDeliverProductList: (data) => {//产品进度列表
        return axios.get('/api/api/delivery/product_list',data);
    },
    getUnbindList:(data) => {//可能被取消的绑定列表
        return axios.get('/products/agent/cancelbind/maybe/list',data);
    },
}