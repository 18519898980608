/* 协议 */
export default [  
    {
        path: 'agreement/list',
        name: 'agreement-list',
        meta: {
            title: '协议查看'
        },
        component: () =>
            import ('@/views/agreement/list.vue')
    },
    {
        path: 'agreement/index',
        name: 'agreement-index',
        meta: {
            title: '协议'
        },
        component: () =>
            import ('@/views/agreement/index.vue')
    },
    {
        path: 'agreement/indexContent',
        name: 'agreement-indexContent',
        meta: {
            title: '协议'
        },
        component: () =>
            import ('@/views/agreement/indexContent.vue')
    },
    {
        path: 'agreement/other',
        name: 'agreement-other',
        meta: {
            title: '协议'
        },
        component: () =>
            import ('@/views/agreement/other.vue')
    },
]