/*买赠相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    giveDetail: (type, id) => {//详情页-买赠详情（买该产品赠送什么）
        return axios.get(`/products/give/detail/${type}/${id}`);
    },
    alert: (type, productId, cl = 0, data) => {//产品详情页-弹框
        return axios.get(`/products/give/alert/${type}/${productId}/${cl}`, data);
    },
    receive: (data) => {//详情页弹框领取
        return axios.post(`/products/give/alert/receive`, data);
    },
    giveProducts: (data) => {//福利列表
        return axios.get('/products/give/products', data);
    },
    giveReceive: (data) => {//领取福利
        return axios.post('/products/give/receive', data);
    },
    giveUsed: (data) => {//使用福利
        return axios.post('/products/give/used', data);
    },
    vipTheirPrice: (data) => axios.get(`/products/prices`, data),//vip获取到手价
    activityClass: (data) => axios.get(`/products/activity/class/info`, data),//活动所有班型
    confirmOrder: (groupId, data) => axios.post(`/api/api/good/order/confirm/${groupId}`, data),//确认订单
    payOrder: (groupId, data) => axios.post(`/api/api/good/order/pay/${groupId}`, data),//提交订单
    packageSingle: (id) => axios.get(`/api/api/product/package/single/${id}`),//买赠套餐信息
    appointDesc: (data) => axios.get(`/api/api/appoint/user/desc`,data),//约见下单
    getClassNum: (acitvityId, data) => {//活动--获取报名人数和到手价
        return axios.get('/products/activity/detail/class_num/' + acitvityId, data);
    },
}