// 占位图
const defaultOccupyBitmapCover = 'https://image.xlzhao.com/noImg.png';
const field = ''

export default {
    defaultOccupyBitmapCover
}

// 拓展字段

