/*到手价下单相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    vipOrder: (data) => axios.requestPost(`/v1/ucentor/mechanisms`, data),//vip下单
    columnOrder: (data) => axios.requestPost(`/v1/ucentor/vips`, data), //栏目下单
    videoOrder: (data) => axios.requestPost(`/v2/ucentor/pays`, data), //视频下单
    profileOrder: (data) => axios.requestPost(`/v1/ucentor/repositories/order`, data), //资料下单
    activityOrder: (data) => axios.post(`/api/api/teacher/activity/payment`, data), //视频下单
    giveOrder: (data) => axios.post('/products/give_product/order/payment', data),// 赠好友送--购买人下单
    meetOrder: (data) => axios.post(`/api/api/appoint/user/payment`,data),// 约见下单
}