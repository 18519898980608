/*部落
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {

    /* ----- 介绍 -----*/

    getTribeList: (data) => {//部落列表 
        return axios.get('/api/api/trend/list', data);
    },
    delTribeItem: (data) => {//部落列表删除item
        return axios.post('/api/api/trend/del', data);
    },
    giveTribeUp: (data) => {//点赞
        return axios.post('/api/api/trend/love/add', data);
    },
    cancelTribeUp: (data) => {//取消点赞
        return axios.post('/api/api/trend/love/cancel', data);
    },
    getTopicList: (data) => {//获取话题列表
        return axios.get('/api/api/trend/category/list',data);
    },
    infoProductList: (data) => {//获取产品列表
        return axios.get('/api/api/home/search', data);
    },
    getTrendsData: (data) => {// 动态详情
        return axios.get('/api/api/trend/info', data);
    },
    addTrends: (data) => {// 发布动态
        return axios.post('/api/api/trend/add', data);
    },
    updateTrends: (data) => {//更新动态
        return axios.post('/api/api/trend/update', data);
    },
    addComment: (data) => {//添加评论
        return axios.post('/api/api/trend/comment/add', data);
    },
    delComment: (data) => {//删除评论
        return axios.post('/api/api/trend/comment/del', data);
    },
    

    

    
    










    getTicketBy: (data) => {// 领券购买
        return axios.post('', data);
    },
    giveClss: (data) => {// 赠送好友
        return axios.post('', data);
    },
    getAdvert: () => { //广告位
        return axios.get('/api/api/column/lanmu/advert');
    },
    getJurisdiction: (id) => { //权限
        return axios.get(`/products/teacher/${id}/authority`);
    },
    getCourseware: (id) => { //课件
        return axios.get(`/products/teacher/${id}/material`);
    },
    /* ----- 目录 -----*/

    getCatalogueHasChapter: (id) => { //目录有章节 无分页
        return axios.get(`/products/teacher/${id}/chapter`); 
    },
    getNoChapterVideos: (id, chapter_id) => {//目录 有章节视频 无分页
        return axios.get(`/products/teacher/${id}/videos?chapter_id=${chapter_id}`);
    },
    getCatalogueNoChapter: (id, data) => {//目录 无章节 有分页
        return axios.get(`/products/teacher/${id}/videos?page=${data.page}&limit=${data.limit}`);
    },

    /* ----- 活动 -----*/
    getActivityData: (id,limit,page) => {//基本数据
        return axios.get(`/products/activity/list?teacher_id=${id}&limit=${limit}&page=${page}`);
    },

    /* ----- 动态 -----*/
    getDynamicData: (id,page) => {//基本数据 （老接口）
        return axios.requestGet(`/v1/dynamics/list/${id}?page=${page}`);
    },

    /* ----- 评价 -----*/
    getEvaluateData: (id,page) => {//基本数据  （老接口）
        return axios.requestGet(`/v1/comment-teachers/assessed/${id}?page=${page}`);
    },

    /* 积分 */
    pointsRule: () => {//获取部落积分
        return axios.get(`/api/api/integral/v1/trends`);
    },
    trendShares: (id) => {//获取部落积分
        return axios.post(`/api/api/trend/${id}/share`);
    },
}