/* 直播相关 */
export default [
    {
        path: 'live-list',
        name: 'live-list',
        meta: {
            title: '瀑布流组件',
        },
        component: () =>
            import('@/components/live/live-list.vue')
    },
    {
        path: 'live/index',
        name: 'liveIndex',
        meta: {
            title: '直播',
            // keepAlive: false,
            isLoading: true,
            // btnTop:'45px',
            // backHome:false,
        },
        component: () =>
            import('@/views/live/index.vue'),
    },
    {
        path: 'live/playback',
        name: 'playback',
        meta: {
            title: '直播回看',
            isLoading: true,
            // btnTop:'45px',
            // backHome:false,
        },
        component: () =>
            import('@/views/live/playback.vue'),
    },
    {
        path: 'live/liveList',
        name: 'liveList',
        meta: {
            title: '直播列表',
            backHome: false,
        },
        component: () =>
            import('@/views/live/liveList.vue')
    },
    {
        path: 'live/livePosters',
        name: 'livePosters',
        meta: {
            title: '海报分享'
        },
        component: () =>
            import('@/views/live/livePosters.vue')
    },
    {
        path: 'live/personalCenter',
        name: 'personalCenter',
        meta: {
            title: ''
        },
        component: () =>
            import('@/views/live/personalCenter.vue')
    },
    {
        path: 'live/recommend',
        name: 'recommend',
        meta: {
            title: '推荐主播'
        },
        component: () =>
            import('@/views/live/recommend.vue')
    },
    {
        path: 'live/audioList',
        name: 'audioList',
        meta: {
            title: '音视频课'
        },
        component: () =>
            import('@/views/live/audioList.vue')
    },
    {
        path: 'live/activityList',
        name: 'activityList',
        meta: {
            title: '线下活动'
        },
        component: () =>
            import('@/views/live/activityList.vue')
    },
    {
        path: 'live/anchorList',
        name: 'anchorList',
        meta: {
            title: '往期直播'
        },
        component: () =>
            import('@/views/live/anchorList.vue')
    },
    {
        path: 'live/followLive',
        name: 'followLive',
        meta: {
            title: '关注的主播'
        },
        component: () =>
            import('@/views/live/followLive.vue')
    },
    {
        path: 'live/liveData',
        name: 'liveData',
        meta: {
            title: '查看直播数据'
        },
        component: () =>
            import('@/views/live/liveData.vue')
    },
]