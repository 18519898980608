/*奖金池
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getbonusApi: (objid,time) => {//奖金池数据
        return axios.get('/api/api/deposit/'+objid+'/'+time);
    },
    getTaskListApi: (objid,taskType,time,Page) => {//作业列表
        return axios.get('/api/api/deposit/list/'+objid+'/'+taskType+'/'+time+'?page='+Page);
    },
    getWhetherTaskApi: (taslD) => {//判断是否是作业
        return axios.get('/api/api/task/info/'+taslD);
    },
    depositDetailedApi: (data) => {// 押金明细列表
        return axios.get('/api/api/deposit/detailed', data);
    },
}