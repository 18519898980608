// 和缓存存储的名称一致
export const MECHANISM_INFO = 'mechanismInfo';// 机构信息
export const USER_INFO = 'userInfo';// 登录后用户信息
export const SHARE_ID = 'shareId';//邀请人id
export const BALANCE = "balance";//用户余额
export const FOLLOW_INFO = "followInfo";//用户余额
export const AGENT_VERSION = "agentVersion";//获取代理权益版本
export const MESSAGE_NUM = "messageNum";//消息数量
export const COMMENT_AUTHORITY = "commentAuthority";//评论权限
export const ENTRY_URL = 'entryUrl';//邀请人id

// mechanismInfo {
//     domain: "http://wx6a600e869db04393.m.xueliangzhaoedu.cn",
//     invite_type: 2,
//     logo: "https://image.xlzhao.com/o_1crrohlrl1jlfkffd0msj3utfc.jpg",
//     mechanism_id: "3194",// 机构编码
//     name: "测试",//名字
//     pay_domain: "http://payment.xueliangzhaoedu.cn",
//     slogan: "我是店铺介绍 啦啦啦 哈哈哈",// 店铺介绍
// }
