/*钱包相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    topUp: (data) => axios.post('/api/api/order/top-up', data),
    topUpInfoApi: () => {//充值相关信息
        return axios.get('/api/api/order/top-up/info');
    },
}