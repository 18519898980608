import store from '../../store/index'
import publicGetShareInfo from '@/common/share/between'
export async function getInformation(to, next, cookToken) {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (!store.state.loginInfo.userInfo) {
        await store.dispatch('loginInfo/getUser', cookToken) //获取用户信息
    }
    if (!store.state.loginInfo.mechanismInfo) {
        await store.dispatch('loginInfo/setMechanism') //获取机构信息
    }
    if (store.state.agent.agentVersion.agent_equity_style === '') {
        await store.dispatch('agent/setAgentVersion');
    }
    await publicGetShareInfo(to)//调用微信分享接口
    next()
}
