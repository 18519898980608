<template>
  <div v-show="show" class="outer_loading">
    <van-loading class="van_loading" type="spinner" size="20px" > 
      <span class="loading_text">加载中...</span>
    </van-loading>
  </div>
</template>

<script>
import { Loading } from "vant";
export default {
  name: "Loading",
  components: {
    [Loading.name]: Loading,
  },
  data() {
    return {
      show: false,
      needBg: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.outer_loading {
  height: 100%;
  width: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 99999;
}
.van_loading{
  margin-top: 150px;
}
.loading_text{
  margin-left: 10px;
}
</style>