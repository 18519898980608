/*账户相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    refundListApi: (data) => {//退款列表
        return axios.get('/api/api/account/detail/refund/list', data);
    },
    getAccountInfo: () => {//获取钱包账户信息
        return axios.get('/api/api/account/detail');
    },
    getAccountList: (_data) => {//获取钱包账户信息-账单明细
        return axios.get('/api/api/account/detail/list',_data);
    },
    getAccountDetail: (accountId) => {//钱包详情页-详情信息
        return axios.get('api/api/account/detail/info?id='+accountId);
    },    
    putAccountCancel: (accountId) => {//钱包详情页-撤销
        return axios.put('api/api/account/detail/cancel/'+accountId);
    },
    getOrderList: (data) => {//开发票列表-订单列表接口 开票： 0 可开票订单，1已开票
        return axios.get('api/api/invoice/v1/orders',data);
    },
    getInvoiceHistoryList: (data) => {//开发票列表
        return axios.get('/api/api/invoice/history',data);
    },
    getInvoicedetailsData: (InvoiceId) => {//开发票详情数据
        return axios.get('/api/api/invoice/details/'+InvoiceId,);
    },
    getInvoicedetailsOrder: (InvoiceId) => {//开发票详情数据
        return axios.get('/api/api/invoice/invoice-order/'+InvoiceId,);
    },
    getInvoicedetailsAddr: (addrId) => {//开发票获取修改地址数据
        return axios.get('/api/api/good/addr/'+addrId,);
    },
    getInvoicedetailsuUpdateAddr: (addrId,data) => {//开发票保存修改数据地址数据
        return axios.post('/api/api/good/addr/update/'+addrId,data);
    },
    getInvoicedetailsuSubeAddr: (data) => {//开发票保存修改数据地址数据
        return axios.post('/api/api/good/addr/add',data);
    },
    getInvoicedetailsuCascader: (typeid) => {//三级联动
        return axios.get('/api/api/area/'+typeid);
    },
    getInvoiceParagraph: (data) => {//获取税号数据
        return axios.get('/api/api/invoice/invoice',data);
    },
    getInvoicePayable: () => {//获取抬头数据
        return axios.get('/api/api/invoice/payable');
    },
    getInvoicePayableDel: (itemId) => {//删除发票抬头
        return axios.iDelete('/api/api/invoice/payable-del/'+itemId);
    },
    getInvoiceExplainData: (data) => {//获取提示语和运费
        return axios.get('/api/api/invoice/invoiceTips',data);
    },
    postInvoiceCreate: (data) => {//发票保存接口
        return axios.post('/api/api/invoice/v1/apply',data);
    },
    getInvoiceGetAddr: (data) => {//发票保存接口
        return axios.get('/api/api/good/addr/list',data);
    },
    getInvoiceGetAddrDel: (itemId) => {//发票删除地址
        return axios.post('/api/api/good/addr/del/'+itemId);
    },
    getCompanyHeader: (itemId) => {//抬头信息获取数据接口
        return axios.get('/api/api/invoice/payable-details/'+itemId);
    },
    editCompanyHeader: (id,data) => {//抬头保存接口
        return axios.post('/api/api/invoice/v1/payable/'+id,data);
    },
    getInvoiceMail: (itemId) => {//发票物流
        return axios.get('/api/api/invoice/mail/'+itemId);
    },
    getInvoiceCompany: (data) => {//开发票--开发票分公司
        return axios.get('/api/api/invoice/v1/getCompany',data);
    },
}