/* 收款相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'payment/receivables',
        name: 'receivables',
        meta: {
            title: '',
        },
        component: () => import('@/views/payment/receivables.vue')
    },
    {
        path: 'payment/success',
        name: 'paySuccess',
        meta: {
            title: '',
        },
        component: () => import('@/views/payment/success.vue')
    },//购买成功页
]