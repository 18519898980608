import * as types from '../types.js'
import { api_message, api_user } from '@/api/interface';
const agent = {
    namespaced: true,
    state: {
        agentVersion: {
            agent_equity_style: '',//代理权益版本
            agent_page_style: '',//代理版本
        },
        messageNum: '',//消息数量
        screen: false,//视频是否全屏
    },
    getters: {
        agentVersion: state => state.agentVersion,
        messageNum: state => state.messageNum,//消息数量
        screen: state => state.screen,//视频是否全屏
    },
    mutations: {
        [types.AGENT_VERSION](state, obj) {//代理版本
            state.agentVersion.agent_equity_style = Number(obj.agent_equity_style);//代理权益版本
            state.agentVersion.agent_page_style = Number(obj.agent_page_style);//代理版本
        },
        [types.MESSAGE_NUM](state, num) {
            state.messageNum = num
        },
        setScreen: (state, v) => {
            state.screen = v;
        }
    },
    actions: {
        setAgentVersion: async ({ commit, rootGetters }) => {
            const { mechanism_id } = rootGetters["loginInfo/mechanismInfo"]
            await api_user.agentVersionApi(mechanism_id).then(res => {
                if (res.code == 200) {
                    commit(types.AGENT_VERSION, res.data)
                }
            })
        },
        setMessageNum: async ({ commit }) => {
            await api_message.unreadApi().then(res => {
                if (res.code == 200) {
                    commit(types.MESSAGE_NUM, res.data.count)
                }
            })
        },

    }
}

export default agent