/*全局搜索相关*/
/* keepAlive:该页面是否缓存
backHome:回到首页按钮是否显示
*/
export default [
    {
        path: 'search/index',
        name: 'searchIndex',
        meta: {
            title: '',
            keepAlive: false,
            isLoading: true
        },
        component: () => import('@/views/search/index.vue')
    },
    {
        path: 'search/moreList',
        name: 'searchMoreList',
        meta: {
            title: '',
            keepAlive: false,
            // isLoading: true
        },
        component: () => import('@/views/search/moreList.vue')
    },
]