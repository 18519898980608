<template>
  <div class="no_authority">
    <img
      class="authority_icon"
      src="../../assets/images/public/no_authority.png"
      alt=""
    />
    <p>您没有访问权限</p>
    <p>如有疑问，请联系客服</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no_authority {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
  .authority_icon {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }
  p {
    font-size: 24px;
    line-height: 33px;
  }
}
</style>