    // 代理
import axios from '../../axios'

export default {
    getAgentList: (data) => axios.get('/products/agent/list', data),// 代理列表
    getPosters: (agentId) => axios.get('/api/api/posters/agent/' + agentId),// 代理海报
    getEquityList: (key, id, data) => axios.get(`/api/api/agent/equity/list/${key}/${id}`, data),// 代理权益列表
    getShareTabs: (agentID) => axios.get(`/api/api/agent/equity/list/4/${agentID}/categories?agent_version=v1 `),// 分享权益分类列表
    discountInfo: (agentID, data) => axios.get(`/api/api/goods/price/agent/${agentID}`, data),// 代理列表-支付优惠明细
    getEquityTabs: () => axios.get('/api/api/agent/my/equity/tab'),// 查看权益-分类列表
    getMyEquity: (data) => axios.get('/api/api/agent/my/equity', data),// 查看权益-数据
    getGiveEquity: (data) => axios.get('/products/agent/give/num', data),// 查看权益-赠票使用
    // batchUnderway: (data) => axios.get('/api/api/product/batch/underway', data),// 代理获取分批信息 放public了

    getGiveNum: (data) => axios.get('/products/agent/give/detail/num', data),// 代理赠送名额
    getAgentContent: (agentId) => axios.get('/products/agent/details/'+agentId),// 代理详情
    getCouponList: (agentId) => axios.get('/products/agent/details/'+agentId+'/self/coupons'),// 代理详情-优惠券
    getOtherInfo: (agentId) => axios.get('/products/agent/details/'+agentId+'/other'),// 代理详情-其他
    getShareList: (agentId,data) => axios.get('/api/api/agent/v1/'+agentId+'/detail/share',data),// 代理详情-分销产品
    getSelfList: (agentId,data) => axios.get('/products/agent/details/'+agentId+'/self/products',data),// 代理详情-本人学习

    getMyShare: (data) => axios.get('/api/api/agent/v1/0/my/share', data),// 查看权益-可分销产品
    getOtherCoupon: (data) => axios.get('/products/agent/equity/coupons', data),// 查看权益-其它-优惠券
    getSelfStudy: (data) => axios.get('/products/agent/equity/study', data),// 查看权益-自己学
    
}