export function wordsCut(chars, maxNum) {
    // 字符截取  chars字符串  maxNum 最大数量
    let sum = 0,
        _index = 0,
        str = "";
    for (let i = 0; i < chars.length; i++) {
        let c = chars.charCodeAt(i);
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            sum++;
        } else {
            sum += 2;
        }
        if (sum <= maxNum * 2) {
            _index = i;
            str += chars[i];
        } else {
            if (_index > 0 && _index == i - 1) {
                str += "...";
            }
        }
    }
    return str;
}
export function stringCut(chars, maxNum) {
    // 上面的方法只截取数字不截取星号
    // 字符截取  chars字符串  maxNum 最大数量
    let str = chars.substr(0,maxNum)
    if(chars.length > maxNum){
        str += '...'
    }
    return str;
}