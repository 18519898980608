/*免费视频相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getDetail: (id,mechanismId) => {//打卡列表
        return axios.get('/api/api/video_promotion/' +id+'?mechanism_id='+mechanismId);
    },
    getVideoList: (data) => {//视频列表
        return axios.get('/api/api/video_promotion',data);
    },
    getByIds: (data) => {//通过IDS获取视频内容
        return axios.post('/api/api/video_promotion/get_by_ids',data);
    },
}