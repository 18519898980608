/*学习记录相关*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'learningRecord/index',
        name: 'learningRecordIndex',
        meta: {
            title: '学习记录',
        },
        component: () => import('@/views/learningRecord/index.vue')
    },
]