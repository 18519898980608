/** vip相关 */
export default [
    {
        path: 'svip/index',
        name: 'svip-index',
        meta: {
            title: '会员',
            isShowTab: false,
            iconTop: '4.36rem',
        },
        component: () => import('@/views/svip/index.vue')
    },

]