//获取作者id
import VueCookies from 'vue-cookies';
import store from '../../store'
import storage from './storage'
import { Base64 } from "js-base64";
export const getAuthorId = () => {
    let author_id = 0;
    let authorInfo = storage.getItem('authorInfo') || VueCookies.get('authorInfo'); //存储的authorInfo
    if (authorInfo) {
        const { shareId } = store.state.share;
        authorInfo = JSON.parse(Base64.decode(decodeURIComponent(authorInfo)));
        if ((shareId > 0) && shareId == authorInfo[0].share_uid) {
            //    判断没有分销人时，是否给作者分销
            if (authorInfo[0].author_id > 0) {
                author_id = authorInfo[0].author_id;
            }
            // if (shareId > 0) {
            //     if ((shareId == authorInfo[0].share_uid) && (authorInfo[0].author_id > 0)) {
            //         author_id = authorInfo[0].author_id
            //     }
            // } else {
            //     //    判断没有分销人时，是否给作者分销
            //     if (!authorInfo[0].share_uid && (authorInfo[0].author_id > 0)) {
            //         author_id = authorInfo[0].author_id;
            //     }
        }
    }
    return author_id;
}
