import Vue from 'vue'
import Vuex from 'vuex'
import loginInfo from './modules/loginInfo'// 机构信息
import share from './modules/share'// 分享信息和第一次进入页面的链接
import agent from './modules/agent'//公用信息
import specialColumn from './modules/specialColumn'//专栏详情
import tribe from './modules/tribe'//公用信息
import relatives from './modules/relatives'//专栏详情
import depositClass from './modules/depositClass'//专栏详情
import home from './modules/home'//首页装修

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        loginInfo,
        share,
        agent,
        relatives,
        specialColumn,
        tribe,
        depositClass,
        home
    }
})
