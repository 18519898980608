import axios from '../axios'
export default {
    wxLoginApi: (data) => axios.post('/auth/login/third/wechat', data), // 机构授权登录
    wxXlzApi: (data) => axios.post('/auth/login/bind', data), // 学两招授权登录
    userApi: (data) => axios.post('/auth/login/token', data),//通过token获取用户信息
    homeApi: () => {// 首页数据
        return axios.get('/api/api/home')
    },

    SubScribeApi: (mechanismId) => {
        return axios.requestGet('/v2/mechanisms/subscribes/' + mechanismId)
    },
    subscribeBannerApi: (mechanismId) => {// 订阅banner
        return axios.requestGet('v1/mechanisms/banner/' + mechanismId)
    },
    subscribeTopApi: (mechanismId) => {// 订阅top图 
        return axios.requestGet('v1/mechanisms/price/' + mechanismId)
    },
    liveApi: (data) => {// 直播列表
        return axios.get('/api/api/goods_live', data)
    },
    mechanismApi: () => {// 会员中心数据
        return axios.get('/api/api/center/tab/wap')
    },
    messageApi: (mechanismId) => {// 消息列表接口
        return axios.requestGet('/v1/messages/home/' + mechanismId)
    },
    classifyApi: () => {// 分类列表页接口
        return axios.get('/api/api/category-page/v2')
    },
    balanceApi: () => {//获取余额接口
        return axios.requestGet('/v1/ucentor/users/count');
    },
    agentVersionApi: (mechanismId) => {//获取代理权益版本接口
        return axios.requestGet('/v2/agent-configs/version/' + mechanismId);
    },
    exchange: (data) => axios.post('/auth/login/swap-token', data),//随机串交换token
    accredit: (data) => axios.post('/auth/login/mechanism-auth', data)//机构授权
}





// //播放当前视频，上个视频暂停播放
// changePlay(e) {
//     var idx = e.target.id ? e.target.id : e.target.dataset.id;
//     var newVis = document.getElementById(idx);
//     if (this.old_id == idx) {
//       if (newVis.paused) {
//         newVis.play();
//       } else if (newVis.play()) {
//         newVis.pause();
//       }
//     } else {
//       if (this.old_id && document.getElementById(this.old_id).play()) {
//         document.getElementById(this.old_id).pause();
//       }
//       if (newVis.paused) {
//         newVis.play();
//       }
//     }
//     this.old_id = idx;
//   }

//去分享，海报
// function publicGoShare(link){
//     let shareHtml ='<div class="public_share_box ovh"><a class="go_share" href="'+link+'">分享</a></div>';
//     $("body").append(shareHtml);
// }