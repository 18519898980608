import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)
const home = {
  namespaced:true,
  state: {
    hasPlaying: null,//是否有正在播放的音频
    curAudioId: null, //当前播放的音频
    playedList: [], //播放过但未播放完的
    scrollPosition: {
      x: 0,
      y: 0
    }
  },

  mutations: {
    hasPlaying(state, datas){
      state.hasPlaying = datas;
    },
    curAudioId: (state, datas) => {
      state.curAudioId = datas;
    },
    playedList: (state, datas, type) => {
      // state.playedList = datas;
      if(type == 'remove'){

        state.playedList = state.playedList.filter(item => item.id !== datas.id);

        return false;
      }
      if (state.playedList.some(item => item.id === datas.id)) {

        let index = state.playedList.findIndex(item => item.id === datas.id);
        state.playedList[index].curDuration = datas.curDuration

      }else{
        state.playedList.push(datas);
      }
      // // 原数组
      // let array = [{ name: 'Alice', age: 20 }, { name: 'Bob', age: 25 }, { name: 'Charlie', age: 30 }];

      // // 当前要添加的对象
      // let newObject = { name: 'Bob', age: 26 };
      
      // // 判断数组中是否包含当前要添加的对象
      // if (array.some(item => item.name === newObject.name && item.age === newObject.age)) {
      //   // 如果包含，则修改原数组中对应位置的对象
      //   let index = array.findIndex(item => item.name === newObject.name && item.age === newObject.age);
      //   array.splice(index, 1, newObject);
      // } else {
      //   // 如果不包含，则将当前要添加的对象添加到原数组末尾
      //   array.push(newObject);
      // }

    },
    saveScrollPosition(state, position) {
      state.scrollPosition = position
    }
  },

  getters: {
    getHasPlaying: (state) => {
      return state.hasPlaying
    },
    getCurAudioId: (state) => {
      return state.curAudioId
    },
    getPlayedList: (state) => {
      return state.playedList
    }
  },

  actions: {
    setHasPlaying: (context, data) => {
      context.commit('hasPlaying', data);
    },
    setCurAudioId: (context, data) => {
      context.commit('curAudioId', data);
    },
    setPlayedList: (context, data, type) => {
      context.commit('playedList', data);
    },
    deletePlayedItem: (context, data, type) => {
      context.commit('playedList', data, 'remove');
    }
  }
}
export default home
