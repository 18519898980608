/* //我是专家-约见 */
export default [
  {
    path: 'expert/meet',
    name: 'expertmeet',
    meta: {
      title: '订单查询',
      backHome: false,
    },
    component: () => import('@/views/expert/meet.vue')
  },
  {
    path: 'expert/adjustment',
    name: 'meetAdjustment',
    meta: {
      title: '调整专家',
    },
    component: () => import('@/views/expert/adjustment.vue')
  },
  {
    path: 'expert/adjustmentsort',
    name: 'meetManage',
    meta: {
      title: '调整专家',
    },
    component: () => import('@/views/expert/adjustmentSort.vue')
  },
  {
    path: 'expert/look',
    name: 'meetLook',
    meta: {
      title: '查看专家',
    },
    component: () => import('@/views/expert/look.vue')
  },
  {
    path: 'expert/feedback',
    name: 'meetFeedback',
    meta: {
      title: '专家反馈',
    },
    component: () => import('@/views/expert/feedback.vue')
  },
  {
    path: 'expert/lookFeedback',
    name: 'meetlookFeedback',
    meta: {
      title: '专家反馈',
    },
    component: () => import('@/views/expert/lookFeedback.vue')
  },
  {
    path: 'expert/edit',
    name: 'meetEdit',
    meta: {
      title: '编辑资料',
    },
    component: () => import('@/views/expert/edit.vue')
  },
  {
    path: 'expert/homepage',
    name: 'meetEdit',
    meta: {
      title: '专家主页',
    },
    component: () => import('@/views/expert/homepage.vue')
  },
  {
    path: 'expert/earningsDetails',
    name: 'earningsDetails',
    meta: {
      title: '查看详情',
    },
    component: () => import('@/views/expert/earningsDetails.vue')
  },
  {
    path: 'expert/conversionRules',
    name: 'conversionRules',
    meta: {
      title: '转化规则',
    },
    component: () => import('@/views/expert/conversionRules.vue')
  },
]