/** 部落 */
export default [
    {
        path: 'tribe/index',
        name: 'tribe',
        meta: {
            title: '部落',
            isShowTab: true,
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/tribe/index.vue')
    },
    {
        path: 'tribe/tribe_topics',
        name: 'tribe-topics',
        meta: {
            title: '部落话题',
            isShowTab: false,
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/tribe/tribe_topics.vue')
    },
    {
        path: 'tribe/tribe_dynamics',
        name: 'tribe-dynamics',
        meta: {
            title: '详情',
            isShowTab: false,
        },
        component: () => import('@/views/tribe/tribe_dynamics.vue')
    },
    {
        path: 'tribe/search',
        name: 'tribe-search',
        meta: {
            title: '搜索',
            isShowTab: false,
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/tribe/search.vue')
    },
    {
        path: 'tribe/topic',
        name: 'tribe-topicList',
        meta: {
            title: '话题',
            isShowTab: false,
        },
        component: () => import('@/views/tribe/topic.vue')
    },
]