/* 一级页面 */
import routes from '../routerFiles/index'
export default [
    {
        path: '/page',
        name: 'page',
        meta: {
            title: '',
        },
        component: () => import('@/views/page/page.vue'),
        children: routes
    },
]