/* 打卡抽奖 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'bonus/index',
        name: 'bonus',
        meta: {
            title: ''
        },
        component: () => import('@/views/bonus/index.vue')
    },
    {
        path: 'bonus/depositDetail',
        name: 'bonusdepositDetail',
        meta: {
            title: '押金明细'
        },
        component: () => import('@/views/bonus/depositDetail.vue')
    }
]