/*订单查询
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getorderQueryTab: () => {//获取全部订单类型
        return axios.get('/api/api/order/types');
    },
    getorderQueryList: (data) => {//订单搜索列表
        return axios.get('/api/api/order/oversell/me',data);
    },
    liveList: (data) => {//直播订单搜索列表
        return axios.get('/api/api/live/anchor/sale-list',data);
    },    
    checkPhone: (data) => {//获取手机号
        return axios.get('/api/api/user/mobile',data);
    },
}