/* 押金相关 */
export default [
    {
        path: 'extensionPoster/list',
        name: 'extensionPosterlist',
        meta: {
            title: '海报推广',
        },
        component: () =>
            import ('@/views/extensionPoster/list.vue')
    },
    {
        path: 'extensionPoster/platformList',
        name: 'extensionPosterPlatformList',
        meta: {
            title: '推平台',
        },
        component: () =>
            import ('@/views/extensionPoster/platformList.vue')
    },
    {
        path: 'extensionPoster/activityList',
        name: 'extensionPosteractivityList',
        meta: {
            title: '推活动',
        },
        component: () =>
            import ('@/views/extensionPoster/activityList.vue')
    },
    {
        path: 'extensionPoster/columnList',
        name: 'extensionPostercolumnList',
        meta: {
            title: '推栏目',
        },
        component: () =>
            import ('@/views/extensionPoster/columnList.vue')
    },
    {
        path: 'extensionPoster/taskList',
        name: 'extensionPostertaskList',
        meta: {
            title: '推打卡',
        },
        component: () =>
            import ('@/views/extensionPoster/taskList.vue')
    },
    {
        path: 'extensionPoster/agentList',
        name: 'extensionPosteragentList',
        meta: {
            title: '推合伙人',
        },
        component: () =>
            import ('@/views/extensionPoster/agentList.vue')
    },
    {
        path: 'extensionPoster/packageList',
        name: 'extensionPosterpackageList',
        meta: {
            title: '推套餐',
        },
        component: () =>
            import ('@/views/extensionPoster/packageList.vue')
    },
    {
        path: 'extensionPoster/liveList',
        name: 'extensionPosterliveList',
        meta: {
            title: '推直播',
        },
        component: () =>
            import ('@/views/extensionPoster/liveList.vue')
    },
    {
        path: 'extensionPoster/campList',
        name: 'extensionPosterCampList',
        meta: {
            title: '推训练营',
        },
        component: () =>
            import ('@/views/extensionPoster/campList.vue')
    },
    {
        path: 'extensionPoster/depositList',
        name: 'extensionPosterDepositList',
        meta: {
            title: '推订金课',
        },
        component: () =>
            import ('@/views/extensionPoster/depositList.vue')
    },
]