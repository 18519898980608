/*套餐相关*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'package/packageList',
        name: 'packageList',
        meta: {
            title: '套餐',
            keepAlive: false,
        },
        component: () => import('@/views/package/packageList.vue')
    },
    {
        path: 'package/packageDetails',
        name: 'packageDetails',
        meta: {
            title: '套餐详情',
            keepAlive: false,
        },
        component: () => import('@/views/package/packageDetails.vue')
    },
    {
        path: 'package/material',
        name: 'packageMaterial',
        meta: {
            title: '分享素材',
        },
        component: () => import('@/views/package/material.vue')
    },
    {
        path: 'package/purchase',
        name: 'packagePurchase',
        meta: {
            title: '购买的套餐',
        },
        component: () => import('@/views/package/purchase.vue')
    },
    {
        path: 'package/evaluationDetails',
        name: 'evaluationDetails',
        component: () => import('@/views/package/evaluationDetails.vue')
    },
    {
        path: 'package/purchasedList',
        name: 'purchasedList',
        meta: {
            title: '',
        },
        component: () => import('@/views/package/purchasedList.vue')
    },
    //报名信息填写
    {
        path: 'package/signInfo',
        name: 'signInfo',
        meta: {
            title: '',
        },
        component: () => import('@/views/package/signInfo.vue')
    },
]