import Vue from 'vue'
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
if (process.env.VUE_APP_CURRENTMODE === 'prod') {
  Raven.config('https://c29c8c3fd7b54333946a3f443775a26c@sentry.xlzhao.com/19',//对应生成的DSN
    {
      release: '1.0.0'
    }
  )
    .addPlugin(RavenVue, Vue)
    .install()
}
import App from './App.vue'
import store from './store'
import router from './router'
import glbUrl from '@/api/config'
import '@/assets/css/common.scss'
import backTop from '@/components/public-components/back-top'//回到顶部组件
/*
空状态组件 ---通过添加ref属性然后调用 this.$refs.empty.show()显示、this.$refs.empty.hidden()隐藏 ，
组件传值：top：top值，content：内容
*/
import emptyState from '@/components/public-components/empty-state'
import storage from '@/common/uilt/storage'
import VueCookies from 'vue-cookies'//vue-cookies插件
import Video from 'video.js';
import publicGetShareInfo from '@/common/share/between'//分享方法
import appSource from '@/common/uilt/source';//判断是ios 或者是 android
import 'video.js/dist/video-js.css';
// import hls from 'videojs-contrib-hls';
import '@/common/uilt/correction'
import { getUrlParam } from '@/common/uilt/getUrlParam'
import { formatDate } from '@/common/uilt/formatDate'
import { formatNumber } from '@/common/uilt/conversion'//数字单位转换方法
import { wordsCut, stringCut } from '@/common/uilt/wordsCut'//数字单位转换方法
import processEvent from "@/common/uilt/timerControl";  //时间处理（可新增）
import { getAuthorId } from "./common/uilt/getAuthorId";//获取作者id
import 'amfe-flexible/index.min.js'
import { Toast, Dialog, Lazyload, Loading } from 'vant';
import ModalLoading from "@/common/switchLoading/switchLoading";
import noAuthority from "@/common/noAuthority/noAuthority";//禁止访问页面
import timeout from "@/common/timeout/timeout";
import VueWechatTitle from 'vue-wechat-title'// 修改顶部标题  解决部分IOS document.title不能修改问题
import globalVariable from "@/common/globalVariable/global_variable.js"; //通用公共变量
Vue.use(noAuthority);
Vue.use(ModalLoading);
Vue.use(timeout);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Lazyload, {
  lazyComponent: true,
  preLoad: 1.5,
  error: 'https://image.xlzhao.com/icongoods-loading.jpg',       // 加载失败默认图片
  loading: 'https://image.xlzhao.com/icongoods-loading.jpg',     // 加载中默认图片
});
Vue.use(VueWechatTitle);
import adapterIphone from './common/uilt/adapter';//适配iphone
// adapterIphone()
Vue.prototype.$conversion = formatNumber;//挂载数字单位转换方法到原型 调用：this.$conversion ;
Vue.prototype.$wordscut = wordsCut;//字符截取到多少个 调用：this.$wordscut;
Vue.prototype.$stringCut = stringCut;//字符截取到多少个 调用：this.$wordscut;
Vue.prototype.$video = Video;//视频插件挂载
Vue.prototype.$publicGetShareInfo = publicGetShareInfo;//分享方法挂载
Vue.prototype.$staticUrl = glbUrl.static_url;//静态链接
Vue.prototype.$socketUrl = glbUrl.socket_url;//长连接
Vue.prototype.$cookies = VueCookies;//Cookies挂载
Vue.prototype.$storage = storage;//storage挂载
Vue.prototype.$appSource = appSource;//判断是ios 或者是 android
Vue.prototype.$getUrlParam = getUrlParam;//获取地址栏参数
Vue.prototype.$getAuthorId = getAuthorId;//获取作者id挂载
Vue.prototype.$adapterIphone = adapterIphone;//适配苹果底部
Vue.prototype.$sevenImageUrl = 'https://image.xlzhao.com/';//七牛云图片域名
Vue.prototype.$sevenVideoUrl = 'https://data.xlzhao.com/';//七牛云视频域名
Vue.prototype.$sevenAudioUrl = 'https://audio.xlzhao.com/';//七牛云音频域名
Vue.prototype.$sevenFileUrl = 'https://file.xlzhao.com/';//七牛云文件域名
Vue.prototype.$noMore = '没有更多了';
Vue.prototype.$formatDate = formatDate;
Vue.prototype.$processEvent = processEvent;
Vue.prototype.$defaultImage = 'https://image.xlzhao.com/noImg.png'; //默认占位图
Vue.prototype.GLOBAL = globalVariable;
Vue.component('backTop', backTop)
Vue.component('emptyState', emptyState)
Vue.config.productionTip = false
// Vue.config.ignoredElements = ['wx-open-launch-weapp']
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
