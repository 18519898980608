import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)
const specialColumn = {
    namespaced: true,
    state: {
        addTopicShow: false,//添加话题弹窗
        addProductShow: false,//添加产品弹窗
        goodsObj: null, //商品id
        topicObj: null, //话题id
        comments: "", //评论内容
        replayShow: false, //回复评论弹窗
        isTopicPage: false, //是在话题页面
    },

    mutations: {
        topicShow(state, data) {
            state.addTopicShow = data;
        },
        productShow(state, data) {
            state.addProductShow = data;
        },
        goodsObj(state, data) {
            state.goodsObj = data;
        },
        topicObj(state, data) {
            state.topicObj = data;
        },
        comments(state, data) {
            state.comments = data;
        },
        replayShow(state, data) {
            state.replayShow = data;
        },
        topicPage(state, data) {
            state.isTopicPage = data;
        },
    },

    getters: {
        getAddTopicShow: (state) => {
            return state.addTopicShow
        },
        getAddProductShow: (state) => {
            return state.addProductShow
        },
        getGoodsObj: (state) => {
            return state.goodsObj
        },
        getTopicObj: (state) => {
            return state.topicObj
        },
        getComments: (state) => {
            return state.comments
        },
        getReplayShow: (state) => {
            return state.replayShow
        },
        getIsTopicPage(state) {
            return state.isTopicPage 
        },
    },

    actions: {
        setAddTopicShow: (context, data) => {
            context.commit('topicShow', data);
        },
        setAddProductShow: (context, data) => {
            context.commit('productShow', data);
        },
        setGoodsObj: (context, data) => {
            context.commit('goodsObj', data);
        },
        setTopicObj: (context, data) => {
            context.commit('topicObj', data);
        },
        setComments: (context, data) => {
            context.commit('comments', data);
        },
        setReplayShow: (context, data) => {
            context.commit('replayShow', data);
        },
        setIsTopicPage: (context, data) => {
            context.commit('topicPage', data);
        },
    }
}
export default specialColumn
