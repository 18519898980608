/*押金玩法
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getBonusData: (taskId) => {//基本数据
        return axios.get(`products/camp/tasks/${taskId}/deposits`);
    },
    getAnyDay: (data) => {// 前一天 后一天
        return axios.post('', data);
    },
    getPastDay: (data) => {// 获取往日数据
        return axios.post('', data);
    },
    getClockInData: (campId, data) => {//获取打卡数据
        return axios.get(`/products/camp/${campId}/users`, data);
    },
    getDetailedData: (campId, data) => {//查看明细
        return axios.get(`/products/camp/${campId}/deposits/records`, data);
    },
    depositCalendar: (campId,data) => {//押金玩法-日历 
        return axios.get(`/products/camp/${campId}/dates`,data);
    }
}