/* 购买成功 */
/* keepAlive:该页面是否缓存 */
export default [
  {
      path: 'buySuccess/index',
      name: 'buySuccess',
      meta: {
          title: ''
      },
      component: () => import('@/views/buySuccess/index.vue')
  }
]