<template>
  <transition name="lyric">
    <div id="default_drag_return" :style="{ bottom: distance}" v-show="show" @click.stop="topreturn"></div>
  </transition>
</template>

<script>
export default {
  name: "returntop",
  props: {
    distance: [String],
    target: [String],
  },
  data() {
    return {
      show: false,
      container: "",
      el: "",
    };
  },
  mounted() {
    this.init();
    this.container.addEventListener(
      "scroll",
      this.debounce(this.throttledScrollHandler, 100)
    );
  },
  methods: {
    init() {
      this.container = document;
      this.el = document.documentElement;
      if (this.target) {
        this.el = document.getElementById(this.target);
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`);
        }
        this.container = this.el;
      }
    },
    throttledScrollHandler() {
      var scrollTop =
        this.container.scrollTop ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 400) {
        this.show = true;
      }
      if (scrollTop < 400) {
        this.show = false;
      }
    },
    debounce(method, delay) {
      let timer = null;
      return function () {
        let self = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          method.apply(self, args);
        }, delay);
      };
    },
    topreturn() {
      let top =
        this.container.scrollTop ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        if (this.target) {
          this.container.scrollTop = top -= 20;
        } else {
          document.documentElement.scrollTop = document.body.scrollTop = top -= 20;
        }
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5);
    },
  },
};
</script>
<style scoped>
#default_drag_return {
  position: fixed;
  right: 20px;
  bottom: 270px;
  width: 100px;
  height: 100px;
  background: url('../../assets/images/empty/icon_go.png')
    no-repeat center;
  background-size: 100px 100px;
  z-index: 2;
}
.lyric-enter,
.lyric-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.lyric-enter-to,
.lyric-leave {
  opacity: 1;
}
.lyric-enter-active,
.lyric-leave-active {
  transition: all 0.5s;
}
</style>
