import timeout from '@/components/timeout/timeout.vue'
const ModalTimeout = {}
ModalTimeout.install = function (Vue) {
    // 在全局状态下只有一个实例 options = {}option = {}
    let instance;
    let defaultOptions = {
        needBg: true // 默认需要背景
    }
    // 在Vue的原型上扩展方法
    Vue.showTimeout = Vue.prototype.$showTimeout = function (option) {
        defaultOptions = Object.assign(defaultOptions, option)
        // 实例化所引入的插件
        const ModalLoadController = Vue.extend(timeout)
        // 判断当前实例是不是已经存在
        if (!instance) {
            // 实例化插件并挂在到某一个元素上
            instance = new ModalLoadController({
                el: document.createElement('div')
            });
            // 添加在body内
            document.body.appendChild(instance.$el)
        }
        if (instance.show) return
        instance.show = true
        instance.needBg = defaultOptions.needBg
    };
    Vue.closeTimeout = Vue.prototype.$closeTimeout = function () {
        instance ? (instance.show = false) : ''
    }
}
export default ModalTimeout