/*金牌客户
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getAdviserInfo: () => axios.get('/api/api/agent/helper/apply'),//获取信息
    getAdviserSub: (data) => axios.post('/api/api/agent/helper/apply', data),//提交个人信息
    getAdviserModify: (id,data) => axios.post('/api/api/agent/helper/modify-information/'+id, data),//修改个人信息
    getAdviserreturnList: (data) => axios.get('/api/api/agent/helper/index',data),//退回记录
    putAdviserreOrder: (data) => axios.put('/api/api/agent/helper/apply',data),//接单
}