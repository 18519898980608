import axios from '../axios'

export default {
    basicSettings: (data) => axios.get('/api/api/mechanism_vip/basic_settings', data),// svip基础信息
    giveProduct: (data) => axios.get('/api/api/mechanism_vip/give_product/' + data.type, data),// 获取产品
    repositoryAllPrice: (data) => axios.get('/api/api/repository/all_price', data),// 获取资料库价值
    vipFreeLanmu: (data) => axios.get('/api/api/mechanism_vip/vip_free_lanmu', data),// svip栏目模块相关
    exclusiveActivity: (data) => axios.get('/api/api/mechanism_vip/vip_exclusive_activity', data),// 活动专属折扣
    superRecommend: (data) => axios.get('/api/api/appoint/super/recommend', data),// 约见专属折扣
    couponsReceive: (data) => axios.post('/api/api/coupon/receive', data),// 领取优惠劵
    vipExclusiveGoods: (data) => axios.get('/api/api/mechanism_vip/vip_exclusive_goods', data),// 商城专属折扣
    // couponsReceive: (data) => axios.requestPost('/v1/ucentor/coupons/receive', data),// 领取优惠劵
    customShare: (data) => axios.get('/api/api/custom-share?mechanism_id=' + data.mechanism_id + '&goods_type=3&goods_id=0', data),// 获取分享信息
}