/*套餐相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    packageListApi: (data) => {//套餐列表
        return axios.get('/api/api/product/package', data);
    },
    packageDetailsApi: (id) => {//套餐详情
        return axios.get('/api/api/product/package/' + id);
    },
    packagePosterApi: (product_type, sub_product_id, type, product_id, page) => {//获取海报
        return axios.get(`/products/poster?product_type=${product_type}&sub_product_id=${sub_product_id}&type=${type}&product_id=${product_id}&page=${page}`);
    },
    packageMaterialApi: (id) => {//海报(用新接口)、软文、头条、微信分享
        return axios.get('/api/api/share/material/package/' + id);
    },
    getAuthorNameApi: (id) => {//头条信息
        return axios.requestGet('/v1/news/detail/' + id,);
    },
    purchaseApi: (data) => {//我的套餐列表
        return axios.get('/api/api/product/package/myself', data);
    },
    againCommentsApi: (data, id = 0) => {//添加/修改评价
        return axios.post('/api/api/product/package/comment/store/' + id, data);
    },
    commentsDetailsApi: (id) => {//套餐评价的详情显示 
        return axios.get('/api/api/product/package/comment/show/' + id);
    },
    delCommentsApi: (id) => {//删除评论
        return axios.iDelete('/api/api/product/package/comment/' + id);
    },
    packageDetailsCommentApi: (id, data) => {//套餐详情的评论列表
        return axios.get('/api/api/product/package/comment/index/' + id, data);
    },
    buyPackageDetailsApi: (id) => {//我的-购买套餐-查看详情
        return axios.get('/api/api/product/package/myself/' + id);
    },
    isHaveApi: (id) => {//是否已经购买
        return axios.get('/api/api/product/package/is-have/' + id);
    },
    customShareApi: (data) => {// 自定义微信分享
        return axios.get('/api/api/custom-share', data);
    },
    integral: (groupId, data) => {//积分
        return axios.get('/api/api/integral/deduction/' + groupId, data);
    },
    order: (id, data) => axios.post('/api/api/goods/payment/38/' + id, data),//下单接口
    // underway: (data) => axios.get('/api/api/product/batch/underway', data),//获取某个产品是否正在分批付款 放public了
    batch: (data) => axios.get('/api/api/product/batch', data),//获取产品是否支持分批付款 
    paid: (data) => axios.post('/api/api/payment/another/verfiy', data),//检查产品是否支持他人代付
    supportPaid: (data) => axios.get('/api/api/product/another', data),//获取某个套餐/活动是否正在他人代付  
    paidOrder: (data) => axios.post('/api/api/payment/another/payment', data),//获取某个套餐是否正在他人代付  
    mealPopInfoApi: (packageId, activityId) => {//替他报名 弹层信息
        return axios.get('/api/api/product/package/use/activity/info?package_id=' + packageId + '&activity_id=' + activityId);
    },
    signAuthority: (data) => { //是否可以报名 权限校验
        return axios.post('/api/api/teacher/activity/join_authority', data)
    },
    buyAuthority: (id) => axios.get('/api/api/product/package/authority/' + id),//获取某个用户是否有权限购买套餐
    appointmentWith: (packageId, appointId) => axios.get(`/api/api/product/package/use/appoint/info?package_id=${packageId}&appoint_id=${appointId}`),//套餐去使用：约见详情(弹层)
    meetDetails: (data) => axios.post('/api/api/product/package/use/appoint', data),//套餐去使用：领取约见详情
    purchasedList: (packageId) => axios.get(`/api/api/product/package/give_info?package_id=${packageId}`),//已购买套餐内产品列表

    getFormInfo: (id, data) => {//获取报名表单
        return axios.get('/api/api/package-form/' + id);
    },
    submitFormInfo: (data) => {//提交表单
        return axios.post('/api/api/product-form', data);
    },
}