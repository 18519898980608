/* 我的-运营主体 */
export default [
  {
    path: 'mine/operateMain',
    name: 'operateMain',
    meta: {
      title: '运营主体',
    },
    component: () => import('@/views/mine/operateMain.vue')
  },
]