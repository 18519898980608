/*免费视频相关*/
/* keepAlive:该页面是否缓存
backHome:回到首页按钮是否显示
*/
export default [
    {
        path: 'freeVideo/detail',
        name: 'freeVideoDetail',
        meta: {
            title: '',
        },
        component: () => import('@/views/freeVideo/detail.vue')
    },
    {
        path: 'freeVideo/list',
        name: 'freeVideoList',
        meta: {
            title: '视频列表',
        },
        component: () => import('@/views/freeVideo/list.vue')
    },
]