/*  分类相关 */
export default [
    {
        path: 'classify/classification',
        name: 'classification',
        meta: {
            title: '分类',
            isShowTab: true,
            keepAlive: false,
        },
        component: () => import('@/views/classify/classification.vue')
    },

]