/*pk榜
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    PkListDataApi: (data) => {//pk列表
        return axios.get('/api/tools/pk/list',data);
    },
    PkPersonDataApi: (data) => {//pk个人榜详情
        return axios.get('/api/tools/pk/person/detail',data);
    },
    PkInfoDataApi: (data) => {//个人榜--分销榜--我的排名
        return axios.get('/api/tools/pk/person/detail/my/rank',data);
    },
    PkOrderDataApi: (data) => {//订单列表
        return axios.get('/api/tools/pk/detail/order',data);
    },
    PkTeamDataApi: (data) => {//PK榜：战队榜详情
        return axios.get('/api/tools/pk/team/detail',data);
    },
    PkTeamDataOrderApi: (data) => {//PK榜：战队详情--销售列表
        return axios.get('/api/tools/pk/team/detail/order',data);
    },
    PkTeamInfoDataApi: (data) => {//个人榜--分销榜--我的排名
        return axios.get('/api/tools/pk/team/detail/my/rank',data);
    },
}