/*资料相关*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'profile/list',
        name: 'profileList',
        meta: {
            title: '资料',
        },
        component: () => import('@/views/profile/list.vue')
    },
    {
        path: 'profile/details',
        name: 'profileDetails',
        meta: {
            title: '',
        },
        component: () => import('@/views/profile/details.vue')
    },
    {
        path: 'profile/playAudio',
        name: 'profilePlayAudio',
        meta: {
            title: '',
        },
        component: () => import('@/views/profile/playAudio.vue')
    },
    {
        path: 'profile/playVideo',
        name: 'profilePlayVideo',
        meta: {
            title: '',
        },
        component: () => import('@/views/profile/playVideo.vue')
    },
    {
        path: 'profile/preview',
        name: 'profilePreview',
        meta: {
            title: '',
        },
        component: () => import('@/views/profile/preview.vue')
    },
]