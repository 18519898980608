/* 购买成功 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'buySucceeds/buySucceeds',
        name: 'buySucceeds',
        meta: {
            title: ''
        },
        component: () => import('@/views/buySucceeds/buySucceeds.vue')
    }
]