/*约见相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getReceiveList: (from_id) => axios.get('/api/api/receive/list/'+from_id),//待领奖励列表
    getReceive: (from_id) => axios.post('/api/api/receive/'+from_id),//领取接口
    getUniversalfForm: (from_id,_data) => axios.get('/api/api/universal-form/'+from_id,_data),//领取接口
    gethaveSuccess: (_data) => axios.get('/api/api/status-promote/isset',_data),//获取是否有推广产品
    getCode: (mechanismid) => axios.requestGet('/v1/shops/code/'+mechanismid),//获取机构信息
    postUniversalfForm: (_data) => axios.post('/api/api/universal-form',_data),//提交接口
    modifyInterface: (modifyId,_data) => axios.put('/api/api/universal-form/change/'+modifyId,_data),//修改接口
    getresult: (from_id) => axios.get('/api/api/universal-form/history/'+from_id),//表单记录列表
    categoryList: (_data) => axios.get('/api/api/universal-form/category/share',_data),//表单分类分享：获取表单列表
}