/*栏目
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {

    /* ----- 介绍 -----*/

    // getIntrduceData: (id) => {//基本数据
    //     return axios.get(`/products/teacher/${id}`);
    // },
    getIntrduceData: (id, productId) => {//专栏详情数据
        return axios.get(`/products/teacher/${id}?product_id=${productId}`);
    },
    getTicketBy: (data) => {// 领券购买
        return axios.post('', data);
    },
    giveClss: (data) => {// 赠送好友
        return axios.post('', data);
    },
    getAdvert: () => { //广告位
        return axios.get('/api/api/column/lanmu/advert');
    },
    getJurisdiction: (id) => { //权限
        return axios.get(`/products/teacher/${id}/authority`);
    },
    getCourseware: (id) => { //课件
        return axios.get(`/products/teacher/${id}/material`);
    },
    /* ----- 目录 -----*/

    getCatalogueHasChapter: (id) => { //目录有章节 无分页
        return axios.get(`/products/teacher/${id}/chapter`);
    },
    getNoChapterVideos: (id, chapter_id) => {//目录 有章节视频 无分页
        return axios.get(`/products/teacher/${id}/videos?chapter_id=${chapter_id}`);
    },
    getCatalogueNoChapter: (id, data) => {//目录 无章节 有分页
        return axios.get(`/products/teacher/${id}/videos?page=${data.page}&limit=${data.limit}`);
    },

    /* ----- 活动 -----*/
    getActivityData: (id, limit, page) => {//基本数据
        return axios.get(`/products/activity/list?teacher_id=${id}&limit=${limit}&page=${page}`);
    },

    /* ----- 动态 -----*/
    getDynamicData: (id, page) => {//基本数据 （老接口）
        return axios.requestGet(`/v1/dynamics/list/${id}?page=${page}`);
    },

    /* ----- 评价 -----*/
    getEvaluateData: (id, page) => {//基本数据  （老接口）
        return axios.requestGet(`/v1/comment-teachers/assessed/${id}?page=${page}`);
    },
    evaluation: (id) => axios.get(`/products/teacher/score/${id}`),//栏目评分与标签
    commentsList: (id,page) => axios.get(`/products/score/${id}?page=${page}`),//评论列表
}