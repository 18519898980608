/* 账户相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'account/refund',
        name: 'refund',
        meta: {
            title: '退款列表',
            isShowTab: false,
        },
        component: () => import('@/views/account/refund.vue')
    },
    // 账户钱包
    {
        path: 'account/wallet',
        name: 'wallet',
        meta: {
            title: '账户',
        },
        component: () => import('@/views/account/wallet.vue')
    },
    // 钱包-明细详情
    {
        path: 'account/detail',
        name: 'wallet-detail',
        meta: {
            title: '结算详情',
        },
        component: () => import('@/views/account/detail.vue')
    },

    // 开发票
    {
        path: 'account/invoice/list',
        name: 'invoice-list',
        meta: {
            title: '开发票',
        },
        component: () => import('@/views/account/invoice/list.vue')
    },
    // 开发详情
    {
        path: 'account/invoice/details',
        name: 'invoice-details',
        meta: {
            title: '发票详情',
        },
        component: () => import('@/views/account/invoice/details.vue')
    },
    // 开发发票
    {
        path: 'account/invoice/news',
        name: 'invoice-news',
        meta: {
            title: '开发票',
            keepAlive:true,
            backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/account/invoice/news.vue')
    },
    // 开发票修改地址
    {
        path: 'account/invoice/address',
        name: 'addressEdit',
        meta: {
            title: '添加地址',
        },
        component: () => import('@/views/account/invoice/address.vue')
    },
    // 开发票抬头
    {
        path: 'account/invoice/invoiceRise',
        name: 'invoice-invoiceRise',
        meta: {
            title: '添加发票抬头',
        },
        component: () => import('@/views/account/invoice/invoiceRise.vue')
    },
    //开发票物流
    {
        path: 'account/invoice/express',
        name: 'invoice-express',
        meta: {
            title: '物流信息',
        },
        component: () => import('@/views/account/invoice/express.vue')
    },
    //冻结明细
    {
        path: 'account/unsettled',
        name: 'unsettled',
        meta: {
            title: '账户',
        },
        component: () => import('@/views/account/unsettled.vue')
    },
]