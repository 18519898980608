/* 机构本身相关 关于我们*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'group/about',
        name: 'groupAbout',
        meta: {
            title: '关于我们',
            // isShowTab: false,
        },
        component: () => import('@/views/group/about.vue')
    },
    
]