/*亲属相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    relativesListApi: (data) => {//亲属福利列表
        return axios.get('/api/api/teacher/activity/relatives/benefits/list', data);
    },
    shareListApi: () => {//福利分享
        return axios.get('/api/api/teacher/activity/relatives/benefits/share/list');
    },
    receiveGiveInfoApi: (data) => {//亲属福利分享详情
        return axios.get('/api/api/relatives/benefits/share/info',data);
    },
    receiveWelfareApi:(relUid) => {//Ta的福利列表
        return axios.get('/api/api/teacher/activity/relatives/benefits/list/replace?rel_uid='+ relUid);
    },
    receiveSignUpApi:(data) => {//Ta的福利-替他报名-跳转活动详情
        return axios.post('/api/api/teacher/activity/relatives/benefits/receive/replace', data);
    },
    receiveDetailApi:(orderSn) => {//Ta的福利查看详情
        return axios.get('/api/api/teacher/activity/relatives/benefits/replace/detail?order_sn='+ orderSn);
    },
    receivePopInfoApi:(orderSn,relUid) => {//替他报名 弹层信息
        return axios.get('api/api/teacher/activity/relatives/replace/activity/detail?order_sn=' + orderSn + '&rel_uid=' + relUid );
    },
    activityReceiveApi:(data) => {//活动领取
    return axios.post('/api/api/teacher/activity/relatives/benefits/receive/activity',data);
    },
    comprehensiveReceiveApi:(data) => {//录播课/VIP/SVIP/作业/代理/优惠券  领取
        return axios.post('/api/api/teacher/activity/relatives/benefits/receive',data);
    },
    appointReceiveApi:(data) => {//约见领取
        return axios.post('/api/api/teacher/activity/relatives/benefits/receive/appoint',data);
    },
    packageReceiveApi:(data) => {//套餐领取
        return axios.post('/api/api/teacher/activity/relatives/benefits/receive/package',data);
    },
    relativesListApis: () => {//权益列表
        return axios.get('/api/api/good/card/list');
    },
    welfareApi:(data) => {//福利列表
        return axios.get('/products/give-products',data);
    },
    campReceiveApi:(data) => {//训练营领取
        return axios.post('/api/api/teacher/activity/relatives/benefits/receive/camp',data);
    },
    giftApi:(data) => {//福利列表-好友赠送福利
        return axios.get('/products/give_product/get_welfare',data);
    },
    isCheckActivityApi:(data) => {//去验票-校验是否可以验票
        return axios.get('/api/api/teacher/activity/relatives/benefits/replace/activity/if_check',data);
    },
}