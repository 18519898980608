/*金牌客户
i:get || post
h:requestGet ||requestPost
*/
import axios from '../../axios'
export default {
    getCouponList: (data) => axios.get('/api/api/coupon/coupon_promote/coupon_list',data),//推广优惠劵列表
    getCouponDetailsList: (data) => axios.get('/api/api/coupon/coupon_promote/receive_detail/list',data),//推广优惠劵详情列表
    getCouponDetail: (data) => axios.get('/api/api/coupon/coupon_promote/receive_detail/user_detail',data),//推广优惠查看详情事件
    getCouponNum: (data) => axios.get('/api/api/coupon/coupon_promote/receive_detail/num',data),//推广优惠TAB数量
}
