/*头条
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getTabsApi: (mechanismId,data) => {//添加导航选项
        return axios.requestGet('/v1/tasks/tag/'+mechanismId,data);
    },
    getListApi: (mechanismId,data) => {// 头条列表
        return axios.requestGet('/v2/mechanisms/headline-list/'+mechanismId, data);
    },
    getDetail: (newsId) => {// 头条详情内容
        return axios.requestGet('/v1/news/detail/'+newsId);
    },
    // getMyMsgList: (newsId) => {// 头条详情内容
    //     return axios.requestGet('/v1/news/detail/'+newsId);
    // },
    getMsgList: (data) => {// 头条详情内容
        return axios.get('/api/api/news-comment',data);
    },
    postMsg: (data) => {// 头条提交留言
        return axios.post('/api/api/news-comment',data);
    },
    delMsg: (msgId) => {// 头条删除留言
        return axios.iDelete('/api/api/news-comment/'+msgId);
    },
    newShare: (newsId) => {// 头条自定义分享
        return axios.get('/api/api/custom-share/12/' + newsId);
    },
}