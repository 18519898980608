/** 提现相关 */
export default [
    {
        path: 'withdraw/addCard',
        name: 'addCard',
        meta: {
            title: '添加银行卡',
        },
        component: () => import('@/views/withdraw/addCard.vue')
    },
    // 个人提现成功
    {
        path: 'withdraw/personalSuccess',
        name: 'personalSuccess',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/withdraw/personalSuccess.vue')
    },
    // 对公提现成功
    {
        path: 'withdraw/maleSuccess',
        name: 'maleSuccess',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/withdraw/maleSuccess.vue')
    },
    // 个人提现
    {
        path: 'withdraw/personalWithdrawal',
        name: 'personalWithdrawal',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/withdraw/personalWithdrawal.vue')
    },
    // 对公提现
    {
        path: 'withdraw/maleWithdrawal',
        name: 'maleWithdrawal',
        meta: {
            title: '结算',
            isShowTab: false,
        },
        component: () => import('@/views/withdraw/maleWithdrawal.vue')
    },
    // 银行卡
    {
        path: 'withdraw/cardList',
        name: 'cardList',
        meta: {
            title: '银行卡',
        },
        component: () => import('@/views/withdraw/cardList.vue')
    },
    {
        path: 'withdraw/restrict',
        name: 'restrict',
        meta: {
            title: '结算',
            isLoading: true
        },
        component: () => import('@/views/withdraw/restrict.vue')
    },
    // 对公企业认证、签署协议
    {
        path: 'withdraw/maleCardFree',
        name: 'maleCardFree',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/withdraw/maleCardFree.vue')
    },
    // 企业认证状态页面
    {
        path: 'withdraw/authenticationStatus',
        name: 'authenticationStatus',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/withdraw/authenticationStatus.vue')
    },
     //首次结算需要实名认证前置页面
     {
        path: 'withdraw/settlementDetail',
        name: 'settlementDetail',
        meta: {
            title: '结算详情',
        },
        component: () => import('@/views/withdraw/settlementDetail.vue')
    },
]