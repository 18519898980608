import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)
const specialColumn = {
  namespaced:true,
  state: {
    detailsData: null,//栏目详情信息
    activeIndex: null, //tab选项卡点击
  },

  mutations: {
    tabActiveIndex(state, datas){
      state.activeIndex = datas;
    },
    changeDetailsData: (state, datas) => {
      state.detailsData = datas;
    }
  },

  getters: {
    getActiveIndex: (state) => {
      return state.activeIndex
    },
    getDetailsData: (state) => {
      return state.detailsData
    }
  },

  actions: {
    setActiveIndex: (context, data) => {
      context.commit('tabActiveIndex', data);
    },
    setDetailsData: (context, data) => {
      context.commit('changeDetailsData', data);
    },
  }
}
export default specialColumn
