/*数字单位转换*/
/*
* @param {num} num 输入数字. 
* @param {number} dec 小数点后最多位数，默认为2 (传2 && processType == 'price'保留两位小数点)
* @param {string} symbol  默认为w  传chinese 中文单位 万 
*/
export function formatNumber(num, dec, symbol, processType) {
    var malDigit = dec || dec == 0 ? dec : 2;
    var multiple = 1;
    let result = 0;
    for (let i = 0; i < malDigit; i++) {
        multiple *= 10
    }
    num = Number(num);
    if (num == 0) {
        return num + '';
    } else {
        if (num >= 0 && num < 10000) {
            result = Math.floor(num * multiple) / multiple;
            if(dec && dec == 2 && processType == 'price'){
                result = result.toFixed(2)
            }
            return result;
        } else if (num >= 10000 && num < 100000000) {
            var car = num / 10000;
            let symbolFont = symbol == 'chinese' ? '万' : 'w';
            result = Math.floor(car * multiple) / multiple;
            return result + symbolFont;
        } else {
            result = Math.floor((num / 100000000) * multiple) / multiple;
            return result + '亿';
        }
    }
}
