/*打卡相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getListApi: () => {//菜单
        return axios.get('/api/api/home/menu');
    },
    taskInfoApi: (aboutId) => {// 区分作业还是闯关
        return axios.get('/api/api/task/info/' + aboutId)
    },
}