let baseURL = null;
let protocol = window.location.protocol;
if (process.env.NODE_ENV === 'development') {    
    // 设置默认本地开发
    baseURL = {
        api_url: `${protocol}//h.xueliangzhaoedu.cn`,
        new_api_url: `${protocol}//i.xueliangzhaoedu.cn`,
        static_url: `${protocol}//static.xueliangzhaoedu.cn`,
        socket_api_url: 'https://socket.xueliangzhaoedu.cn:9501',
        socket_url: 'wss://socket.xueliangzhaoedu.cn:9501',
        tarzan: `${protocol}//tarzan.xueliangzhaoedu.cn`,
    }
} else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    // 测试
    baseURL = {
        api_url: `${protocol}//h.xueliangzhaoedu.cn`,
        new_api_url: `${protocol}//i.xueliangzhaoedu.cn`,
        static_url: `${protocol}//static.xueliangzhaoedu.cn`,
        socket_api_url: 'https://socket.xueliangzhaoedu.cn:9501',
        socket_url: 'wss://socket.xueliangzhaoedu.cn:9501',
        serviceSideId: 'wxa89bb2c5a1faa121', // 测试环境下的component_appid
        levelDomain: 'm.xueliangzhaoedu.cn',//新用户登录时跳转到学两招域名
        xlz: 'http://m.xueliangzhaoedu.cn/page/oauth2',//测试学两招域名登录下的空白页
        domain:'http://m.xueliangzhaoedu.cn',
        tarzan: `${protocol}//tarzan.xueliangzhaoedu.cn`,
    }
} else if (process.env.VUE_APP_CURRENTMODE === 'prod') {
    // 正式
    baseURL = {
        api_url: `${protocol}//h.xlzhao.com`,
        new_api_url: `${protocol}//i.xlzhao.com`,
        static_url: `${protocol}//static.xlzhao.com`,
        socket_api_url: 'https://socket.xlzhao.com',
        socket_url: 'wss://socket.xlzhao.com',
        serviceSideId: 'wxbec7aa132b7ae5b9', // 正式环境下的component_appid
        levelDomain: 'm.xlzhao.com',//新用户登录时跳转到学两招域名
        xlz: 'http://m.xlzhao.com/page/oauth2',//正式学两招域名登录下的空白页
        domain:'https://m.xlzhao.com',
        tarzan: 'https://tarzan.xlzhao.com',
    }
}
export default baseURL


