/* 约见相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'meet/list',
        name: 'meetList',
        meta: {
            title: '约见',
        },
        component: () => import('@/views/meet/list.vue')
    },
    {
        path: 'meet/index',
        name: 'meetingIndex',
        meta: {
            title: '约见',
        },
        component: () => import('@/views/meet/index.vue')
    },
    {// 约见话题详情
        path: 'meet/topic',
        name: 'meetTopic',
        meta: {
            title: '话题详情',
        },
        component: () => import('@/views/meet/topic.vue')
    },
    {
        path: 'meet/evaluation',
        name: 'meetEvaluation',
        meta: {
            title: '评价详情',
        },
        component: () => import('@/views/meet/evaluation.vue')
    },
    {
        path: 'meet/lookComment',
        name: 'meetLookComment',
        meta: {
            title: '评价详情',
        },
        component: () => import('@/views/meet/lookComment.vue')
    },
    {
        path: 'meet/replyComment',
        name: 'meetReplyComment',
        meta: {
            title: '评价详情',
        },
        component: () => import('@/views/meet/replyComment.vue')
    },
    {
        path: 'meet/appointment',
        name: 'meetAppointment',
        meta: {
            title: '预约时间',
        },
        component: () => import('@/views/meet/appointment.vue')
    },
    {
        path: 'meet/signInfo',
        name: 'meetsignInfo',
        meta: {
            title: '填写报名信息',
        },
        component: () => import('@/views/meet/signInfo.vue')
    },
    {
        path: 'meet/order',
        name: 'meetOrder',
        meta: {
            title: '确认订单',
            // keepAlive: true,
            // backSource:'', //用于判断上一个页面是哪个
        },
        component: () => import('@/views/meet/order.vue')
    },
    {
        path: 'meet/process',
        name: 'meetProcess',
        meta: {
            title: '约见流程',
        },
        component: () => import('@/views/meet/process.vue')
    },
    
    
]