/* 智能客服 相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'customerService/customerDetail',
        name: 'customerDetail',
        meta: {
            title: '智能客服',
        },
        component: () => import('@/views/customerService/customerDetail.vue')
    },
    {
        path: 'customerService/chat',
        name: 'customerChat',
        meta: {
            title: '智能客服',
        },
        component: () => import('@/views/customerService/chat.vue')
    },
    {
        path: 'customerService/list',
        name: 'customerList',
        meta: {
            title: '智能客服',
            isLoading: true
        },
        component: () => import('@/views/customerService/list.vue')
    },
]