/*机构打通相关*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'joint/jointAgent',
        name: 'jointAgent',
        meta: {
            title: '联合代理',
            keepAlive: false,
        },
        component: () => import('@/views/joint/jointAgent.vue')
    },
    {
        path: 'joint/openedSuccessfully',
        name: 'openedSuccessfully',
        meta: {
            title: '联合代理',
            keepAlive: false,
            isLoading:true
        },
        component: () => import('@/views/joint/openedSuccessfully.vue')
    },
]