import { getUrlParam } from '@/common/uilt/getUrlParam'
import wechatParm from "@/api/config";
import { Base64 } from "js-base64";
/*
学两招授权
*/
function authorization() {
    let shareId = getUrlParam('share') || getUrlParam('share_id') || getUrlParam('share_uid') || getUrlParam('shareId') || '';
    let encodeHref = Base64.encode(window.location.href)
    let redirect_uri = encodeURIComponent(`${wechatParm.domain}/open/xlz-wx-authorize?return_url=${encodeHref}&share_uid=${shareId}`)//重定向后台接口
    let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8f6b52ca75c0c349" +
        "&redirect_uri=" +
        redirect_uri +
        "&response_type=code&scope=snsapi_base" +
        "&state=STATE&component_appid=" +
        wechatParm.serviceSideId +
        "#wechat_redirect";
    window.location.replace(url);
}
export default authorization