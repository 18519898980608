/* 押金相关 */
export default [
    {
        path: 'deposit/depositIndex',
        name: 'depositIndex',
        meta: {
            title: '奖金池',
        },
        component: () =>
            import ('@/views/deposit/depositIndex.vue')
    },  
    {
        path: 'deposit/taskDetail',
        name: 'taskDetail',
        meta: {
            title: '押金明细',
        },
        component: () => import('@/views/deposit/taskDetail.vue')
    },
]