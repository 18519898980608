/*约见相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getList: (group_id,data) => axios.requestGet('/v1/repositories/list/'+group_id, data),//资料列表
    readVolume: () => axios.requestPost('/v1/repositories/read-volume'),//增加阅读量
    getDetail: (dataId) => axios.requestGet('/v1/repositories/detail/'+dataId),//获取资料详情

}