/* 活动相关 */
/* keepAlive:该页面是否缓存 */
export default [
  {
    path: 'activity/acitvityList',
    name: 'acitvityList',
    meta: {
      title: '活动列表',
    },
    component: () => import('@/views/activity/acitvityList.vue')
  },
  {//活动转赠转卖--详情
    path: 'activity/giftDetail',
    name: 'activitygiftDetail',
    meta: {
      title: '活动',
    },
    component: () => import('@/views/activity/giftDetail.vue')
  },
  {//活动转赠转卖--领取页详情 --->类似于活动详情页
    path: 'activity/giftReceive',
    name: 'activityGiftReceive',
    meta: {
      title: '活动详情',
    },
    component: () => import('@/views/activity/giftReceive.vue')
  },
  {//活动转赠转卖列表
    path: 'activity/myReceive',
    name: 'myReceive',
    meta: {
      title: '转赠转卖',
    },
    component: () => import('@/views/activity/myReceive.vue')
  },
  {//活动交付区
    path: 'activity/deliverable',
    name: 'activityDeliverable',
    meta: {
      title: '活动',
    },
    component: () => import('@/views/activity/deliverable.vue')
  },
  {//活动交付区-素材课程
    path: 'activity/lesson',
    name: 'activityLesson',
    meta: {
      title: '配套课件',
    },
    component: () => import('@/views/activity/lesson.vue')
  },
  {//活动详情页
    path: 'activity/detail',
    name: 'activityDetail',
    meta: {
      title: '活动',
      isDefault: true,
    },
    component: () => import('@/views/activity/detail.vue')
  },
  {//发布活动评论
    path: 'activity/comment/release',
    name: 'activityReleaseComment',
    meta: {
      title: '发布评价',
    },
    component: () => import('@/views/activity/comment/release.vue')
  },
  {//评论详情
    path: 'activity/comment/detail',
    name: 'activityCommentDetails',
    meta: {
      title: '评论详情',
    },
    component: () => import('@/views/activity/comment/detail.vue')
  },
  {//报名信息
    path: 'activity/signInfo',
    name: 'activitySignInfo',
    meta: {
      title: '填写报名信息',
    },
    component: () => import('@/views/activity/signInfo.vue')
  },
]