/*  首页 */
export default [
    {
        path: 'home/home',
        name: 'newhome',
        meta: {
            title: '首页',
            isShowTab: true,
            // keepAlive: true,
		    // scrollTop: 0,
        },
        component: () => import('@/views/home/home.vue')
    },
    {
        path: 'home/index',
        name: 'home',
        meta: {
            title: '首页',
            isShowTab: true,
        },
        component: () => import('@/views/home/index.vue')
    },
    {
        path: 'home/wholemenu',
        name: 'wholemenu',
        meta: {
            title: '全部菜单',
            isShowTab: false,
        },
        component: () => import('@/views/home/wholemenu.vue')
    },
]