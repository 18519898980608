/*推广活动
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getListApi: (mechanismId) => {//海报推广首页
        return axios.get('/api/api/posters/settings/'+mechanismId);
    },
    getplatformTabApi: (mechanismId) => {// 平台Tab
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/categories');
    },
    getplatformListApi: (type, category_id, page) => axios.get(`/products/poster?type=${type}&category_id=${category_id}&page=${page}`),

    getPublicProductTabApi: (data) => {// 产品共用接Tab
        return axios.get('/api/tools/spread/poster/product/category',data);
    },
    getPublicProductListApi: (data) => {// 产品共用接口列表
        return axios.get(`/api/tools/spread/poster/product`,data);
    },

    getActivitTabApi: (mechanismId) => {// 活动Tab
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/activities/types');
    },
    getActivitListApi: (mechanismId,data) => {// 活动Tab
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/activities',data);
    },
    getPackageListApi: (mechanismId,data) => {// 套餐列表
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/packages',data);
    },
    getLiveTabApi: () => {// 直播Tab
        return axios.get('/api/api/live/group/list');
    },
    getLiveListApi: (mechanismId,data) => {// 直播列表
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/lives',data);
    },
    getTaskTabApi: (mechanismId) => {// 打卡Tab
        return axios.requestGet('/v1/tasks/tag/'+mechanismId);
    },
    getTaskListApi: (mechanismId,data) => {// 打卡列表
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/tasks',data);
    },
    getLanmuTabApi: () => {// 栏目Tab
        return axios.get('/products/teachers-share-category');
    },
    getLanmuListApi: (data) => {// 栏目列表
        return axios.get(`/products/teachers-share`,data);
    },
    getagentListApi: (mechanismId,data) => {// 合伙人列表
        return axios.get('/api/api/posters/mechanism/'+mechanismId+'/agents',data);
    },
    campClassification: () => axios.get(`/products/camp/category`),//海报-训练营分类
    campList: (catId,page) => axios.get(`/products/camp/list?catid=${catId}&page=${page}`),//海报-训练营列表
    getDepositListApi: (data) => axios.get('/products/deposit_lesson/list', data),//海报-订金课列表
}