
<template>
  <div>
    <div v-show="isShow" :style="{ top: top }" class="empty font26">
      {{ content }}
    </div>
  </div>
</template>

<script>
// 空状态组件
export default {
  props: {
    top: {
      type: String,
      default: () => "70px",
    },
    content: {
      type: String,
      default: () => "暂无数据",
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hidden() {
      this.isShow = false;
    },
  },
};
</script>

<style  scoped>
.empty {
  padding-top: 200px;
  line-height: 37px;
  background: url("../../assets/images/empty/temporarily.png") no-repeat center top;
  background-size: 200px 200px;
  text-align: center;
  color: #cacaca;
  position: relative;
  font-size: 24px;
}
/* .empty {
  padding-top: 200px;
  line-height: 37px;
  background: url("../../assets/images/empty/temporarily.png") no-repeat center top;
  background-size: 200px 200px;
  text-align: center;
  color: #cacaca;
  position: relative;
} */
</style>



