/*打卡相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    taskListApi: (mechanismId, data) => {//打卡列表
        return axios.requestGet('/v2/tasks/list/' + mechanismId, data);
    },
    shopsApi: (mechanismId) => {// 打卡tab标签
        return axios.requestGet('/v1/tasks/tag/' + mechanismId)
    },
}