/* 每日心语 */
export default [
  {
    path: 'words/index',
    name: 'wordsIndex',
    meta: {
      title: '早安晚安',
    },
    component: () => import('@/views/words/index.vue')
  },
]