/* 优惠劵*/
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'coupon/getCoupons',
        name: 'couponGetCoupons',
        meta: {
            title: '领取优惠劵',
            keepAlive: false,
        },
        component: () => import('@/views/coupon/getCoupons.vue')
    },
    {
        path: 'coupon/receiveCenter',
        name: 'couponreceiveCenter',
        meta: {
            title: '领劵中心',
            keepAlive: false,
        },
        component: () => import('@/views/coupon/receiveCenter.vue')
    },
    {
        path: 'coupon/myCoupon',
        name: 'couponMyCoupon',
        meta: {
            title: '我的优惠劵',
            keepAlive: false,
        },
        // component: () => import('@/views/coupon/myCoupon.vue')
        component: () => import('@/views/myCoupon/myCouponAll.vue')
    },
    {
        path: 'coupon/couponPast',
        name: 'couponPast',
        meta: {
            title: '优惠劵',
            keepAlive: false,
        },
        component: () => import('@/views/myCoupon/couponPast.vue')
    },
    {
        path: 'coupon/receive',
        name: 'couponReceive',
        meta: {
            title: '领取优惠劵',
            keepAlive: false,
        },
        component: () => import('@/views/coupon/receive.vue')
    },
]