/*商城
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getSwipeApi: (mechanismId) => {//轮播
        return axios.get('/api/api/good/shop/banner/'+mechanismId);
    },
    getPromotionApi: (mechanismId,) => {//促销区接口
        return axios.get('/api/api/good/shop/info/'+mechanismId);
    },
    getTabsApi: (mechanismId,data) => {//添加导航选项
        return axios.get('/api/api/good/group/list/'+mechanismId,data);
    },
    getListApi: (mechanismId,data) => {// 商城列表
        return axios.get('/api/api/good/list/'+mechanismId, data);
    },
    getCartApi: (mechanismId) => {// 购物车数量
        return axios.post('/api/api/good/cart/all/num/'+mechanismId);
    },
    joinCartApi: (mechanismId,data) => {// 加入购物车
        return axios.post('/api/api/good/cart/add/'+mechanismId,data);
    },
    getCartInfoApi: (mechanismId,data) => {// 购物车数据
        return axios.get('/api/api/good/cart/list/'+mechanismId,data);
    },
    DelCartInfoApi: (data) => {// 购物车删除
        return axios.post('/api/api/good/cart/del',data);
    },
    TotalPriceCartApi: (mechanismId,data) => {// 购物车总价格
        return axios.post('/api/api/good/order/confirm/'+mechanismId,data);
    },
    UpdateCartApi: (data) => {// 购物车更改规格
        return axios.post('/api/api/good/cart/update',data);
    },
    AddReduceCartApi: (CartID,data) => {// 购物车更改数量
        return axios.post('/api/api/good/cart/num/'+CartID,data);
    },
    shoppingOrderListApi: (data) => {// 商城订单列表
        return axios.get('/api/api/good/order/listV2/',data);
    },
    shoppingOrderListDelApi: (ItemId) => {//删除的订单
        return axios.post('/api/api/good/order/del/'+ItemId);
    },
    shoppingOrderListConfirmApi: (ItemId) => {//确认订单
        return axios.post('/api/api/good/order/receiving/'+ItemId);
    },
    shoppingDetailsDataApi: (mechanismId,goodsId,data) => {//详情页数据
        return axios.get('/api/api/good/info/'+mechanismId+'/'+goodsId,data);
    },
    shoppingAddressListApi: (data) => {//地址列表
        return axios.get('/api/api/good/addr/list',data);
    },
    shoppingAddressUpdateApi: (AddressId,data) => {//地址设置默认
        return axios.post('/api/api/good/addr/update/'+AddressId,data);
    },
    shoppingAddressGetApi: (AddressId) => {//获取地址
        return axios.get('/api/api/good/addr/'+AddressId);
    },
    shoppingAddressSubApi: (data) => {//地址保存
        return axios.post('/api/api/good/addr/add',data);
    },
    shoppingAddressModifyApi: (AddressId,data) => {//地址修改
        return axios.post('/api/api/good/addr/update/'+AddressId,data);
    },
    shoppingAddressDelApi: (AddressId) => {//地址删除
        return axios.post('/api/api/good/addr/del/'+AddressId);
    },
    shoppingAddressAreaApi: (AddressId) => {//三级联动
        return axios.get('/api/api/area/'+AddressId);
    },
    shoppinglogisticsApi: (logisticsId) => {//物流详情
        return axios.get('/api/api/good/order/mail/info/'+logisticsId);
    },
    shoppinglogApi: (orderId) => {//商品列表物流
    return axios.get('/api/api/good/order/mail/list/' + orderId);
    },
    getEquityCard: (data) => {//权益卡片
        return axios.get('/api/api/good/card/alert',data);
    },
    receiveEquityCard: (data) => {//领取权益卡片
        return axios.post('/api/api/good/card/receive',data);
    },
    getEquityCardDetails: (data) => {//权益卡片详情
        return axios.get('/api/api/good/card/info',data);
    },
    getEquityCardDetailsList: (data) => {//权益卡片详情产品列表
        return axios.get('/api/api/good/card/platform',data);
    },
    getShoppingSelfList: (data) => {//权益卡片详情产品列表
        return axios.get('/api/api/good/take/list',data);
    },
    getShoppingGetSku: (data) => {//获取sku
        return axios.get('/api/api/good/order/param/get',data);
    },
    getShoppingSetSku: (data) => {//提交sku
        return axios.post('/api/api/good/order/param/set',data);
    },
    getEquityCards: (data) => {//详情页权益卡片
        return axios.get('/api/api/good/card/alert/detail',data);
    },
    shoppingCancelOrder: (data) => {//订单列表取消订单
        return axios.post('/api/api/good/order/cancel',data);
    },
    shoppingTranslateList: (data) => {//订单退款列表
        return axios.get('/api/api/good/order/refund/list',data);
    },
    shoppingTranslateDelList: (data) => {//删除订单退款列表
        return axios.post('/api/api/good/order/refund/del',data);
    },
    shoppingTranslateDetailed : (data) => {//商城退货详情
        return axios.get('/api/api/good/order/refund/info',data);
    },
    shoppingService : (mechanismId) => {//商城客服
        return axios.get('/api/api/good/shop/info/'+mechanismId);
    },
    shoppingRefundCancel : (data) => {//商城退货订单用户撤销
        return axios.post('/api/api/good/order/refund/cancel',data);
    },
    shoppingRefund: (data) => {//商城退货
        return axios.post('/api/api/good/order/refund',data);
    },
    shoppingRefundUpdate : (data) => {//商城退货更新
        return axios.post('/api/api/good/order/refund/update',data);
    },
    shoppingOrderinfo : (data) => {//订单详情
        return axios.get('/api/api/good/order/infoV2',data);
    },
    shoppingAutoNumber : (data) => {//商城快递单号自动识别
        return axios.post('/api/api/good/order/mail/auto/number',data);
    },
    shoppingConfList : (data) => {//商城快递公司列表
        return axios.get('/api/api/good/order/mail/conf/list',data);
    },
    shoppingMailSubscribe : (data) => {//商城退货订单用户发货
        return axios.post('/api/api/good/order/refund/mail/subscribe',data);
    },
    shoppingResetUpdate : (data) => {//商城退货重新提交
        return axios.post('/api/api/good/order/refund/reset',data);
    },
}