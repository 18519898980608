import axios from '../axios'
export default {
    getDepositList: (data) => {//订金课列表
        return axios.get('/products/deposit_lesson/list', data);
    },
    getDepositClassApi: (data) => {//订金课详情
        return axios.get('/products/deposit_lesson/info', data);
    },
    getBarrageList: (data) => {//订金课弹幕
        return axios.get('/products/deposit_lesson/order/popup/list', data);
    },
    getWeXinMesApi: (data) => {//已购买去使用获取微信号
        return axios.get('/products/deposit_lesson/order/pay/success', data);
    },
    paymentOrderApi: (data) => {//已购买-去支付定金
        return axios.post('/products/deposit_lesson/order/payment', data);
    },
    cancelOrderApi: (data) => {//已购买-一件退费 取消订单
        return axios.post('/products/deposit_lesson/order/refund', data);
    },
    myClassBuyListApi: (data) => {//我的=>我的课程=>订金课列表
        return axios.get('/api/api/center/courses/deposit_lessons', data);
    },
    depositShareApi: (type, sub_product_id) => {//订金课-分享接口
        return axios.get(`products/custom-share/${type}/${sub_product_id}`);
    },
}