/*打卡相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getInfoApi: (data) => {//专家主页内容
        return axios.get('/products/appoint/expert/home',data);
    },
    putInfoApi: (data) => {//专家主页编辑
        return axios.put('/products/appoint/expert/home',data);
    },
    getTimeApi: (data) => {//获取时间
        return axios.get('/products/appoint/expert/home/time',data);
    },
    putTimeApi: (data) => {//时间设置
        return axios.put('/products/appoint/expert/home/time',data);
    },
    getServiceList: (data) => {//服务收益
        return axios.get('/products/appoint/expert/home/returns',data);
    },
    getConvertList: () => {//转化收益记录
        return axios.get('/products/appoint/expert/home/convert/log');
    },
    getConvertDetail: (id,data) => {//转化收益记录-详情
        return axios.get('/products/appoint/expert/home/convert/log/detail/'+id,data);
    },
    getConvertRule: () => {//转化收益记录-详情
        return axios.get('/products/appoint/expert/home/convert/rule');
    },
    appointTabs: () => {//(新)专家区-订单查询-分类
        return axios.get('/products/appoint/expert/order/category');
    },
    getMeetingList: (data) => {//(新)专家区-订单查询
        return axios.get('/products/appoint/expert/order',data);
    },
    getExpertList: (loggor_id,data) => {//(新)专家区-订单查询-调整专家-指定的
        return axios.get('/products/appoint/expert/order/change_expert/'+loggor_id,data);
    },
    postExpertList: (loggor_id,data) => {//(新)专家区-订单查询-调整专家-指定的
        return axios.post('/products/appoint/expert/order/change_expert/'+loggor_id,data);
    },
    getSortExpertList: (loggor_id,data) => {//(新)专家区-订单查询-调整专家-排序的
        return axios.get('/products/appoint/expert/order/show/'+loggor_id,data);
    },
    getAddSortExpert: (loggor_id,data) => {//(新)专家区-订单查询-调整专家-排序的-添加专家
        return axios.get('/products/appoint/expert/order/get_expert/'+loggor_id,data);
    },
     postFeedback: (data) => {//(新)专家区-订单查询-专家反馈
        return axios.post('/products/appoint/expert/feedback',data);
    },
     getFeedback: (loggor_id) => {//(新)专家区-订单查询-专家反馈
        return axios.get('/products/appoint/expert/feedback/'+loggor_id);
    },
    // 修改时间
    putChangeTime: (loggor_id,data) => {//专家修改时间
        return axios.put('/products/appoint/expert/order/change_time/'+loggor_id,data);
    },
}