import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import routes from './page/page'
import { api_common } from '@/api/interface/index'
// import getUserInfo from '@/common/get-wechat/get-wechat.js';
import authorization from '@/common/get-wechat/authorization.js';
import { getUrlParam } from '@/common/uilt/getUrlParam'
import VueCookies from 'vue-cookies'
import { getInformation } from '@/common/get-wechat/getInformation.js'
import { Base64 } from "js-base64";
const router = new VueRouter({
  mode: 'history',
  routes
})
router.afterEach((to) => {
  if (to.meta.isLoading) {
    Vue.closeLoading();
  }
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  if(!to.meta.isDefault){
    api_common.shareOneApi({
      url: window.location.href,
      title: document.title,
      share_id: store.state.share.shareId,
    })
  }
})
// 路由守卫
router.beforeEach(async (to, from, next) => {
  const cookToken = VueCookies.get('token');//Cookies中token
  if (!to.meta.isLoading && cookToken) {
    Vue.showLoading();
  }
  // const backUrl = sessionStorage.getItem('backUrl')//记录第一次进的页面
  let backUrl = getUrlParam('return_url');
  let share_uid = getUrlParam('share') || getUrlParam('share_id') || getUrlParam('share_uid') || getUrlParam('shareId') || '';//分享人id
  if(share_uid > 0){
    sessionStorage.setItem('shareId',share_uid)
  }
  let nonce = getUrlParam('nonce');
  let code = getUrlParam('code');
  if (process.env.NODE_ENV === 'development') { //本地开发环境
    // b2eb88ccde858bc776edc259dc60f5f4 CRFAODnNUHzHKEyAFY8CygvvffydDVEw
    // 6e463d5e6dd761b8a45a8b62854ae28c  MdA4Wc4OZ6XcEu8wsQVoD9AzQFV4sP84 、713e16f756855361484e78549eb7a021不是代理 39a64b5020dc1d5115a22810b87a12c5
    // await getInformation(to, next, 'b2eb88ccde858bc776edc259dc60f5f4')
    // await getInformation(to, next, '82d39cf683378d0b9eb3dc8577249b9b')
    // await getInformation(to, next, '6e463d5e6dd761b8a45a8b62854ae28c')
    // await getInformation(to, next, 'f5a3e9c8eeb76819d5234177505a117c')
    await getInformation(to, next, '3a90bdecdf82f1c43c03843c19082638')
  } else if (process.env.VUE_APP_CURRENTMODE === 'test') {
    if (cookToken) { //检查cookies是否有token
      if (to.path == '/page/login/index') {
        window.location.replace(Base64.decode(backUrl));
      } else {
        await getInformation(to, next, cookToken)
      }
    } else {
      let ua = navigator.userAgent.toLowerCase()
      let wxBrowser = ua.indexOf('micromessenger') != -1 // 是 否 在微信浏览器内
      if (wxBrowser) {
        if (code) {
          store.dispatch('loginInfo/accreditGetToken', {
            code,
            nonce,
            backUrl,
            next,
            share_uid
          })
        } else {
          if (nonce && to.path == '/page/login/index') {
            next()
          } else {
            authorization()
          }
        }
      } else {
        window.location.href = window.location.origin + '/site/login?return_url=' + window.location.href;//不在微信则跳转到手机号登录页面
      }
    }
  } else if (process.env.VUE_APP_CURRENTMODE === 'prod') {
    if (cookToken) { //检查cookies是否有token
      if (to.path == '/page/login/index') {
        window.location.replace(Base64.decode(backUrl));
      } else {
        await getInformation(to, next, cookToken)//获取用户等相关信息
      }
    } else {
      // await getUserInfo(to, next, cookToken);//去授权
      if (code) {
        store.dispatch('loginInfo/accreditGetToken', {
          code,
          nonce,
          backUrl,
          next,
          share_uid
        })
      } else {
        if (nonce && to.path == '/page/login/index') {
          next()
        } else {
          authorization()
        }
      }
    }
  }
})
export default router
