/*优惠劵相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    couponDetailsApi: (data) => {//优惠劵使用数据
        return axios.get('/api/api/coupon/details', data);
    },
    couponcenterlsApi: (data) => {//领卷中心列表
        return axios.get('/api/api/coupon/list', data);
    },
    couponExchangeApi: (data) => {//兑换优惠劵
        return axios.requestPost('/v1/ucentor/coupons/exchange', data);
    },
    MycouponDataListApi: (data) => {//我的优惠劵列表
        return axios.get('/api/api/coupon/mine', data);
    },
    MycouponDonatetApi: (data) => {//我的优惠赠送
        return axios.post('/api/api/coupon/donate', data);
    },
    couponReceiveDataApi: (data) => {//优惠劵领取数据
        return axios.get('/api/api/coupon/info', data);
    },
    couponReceiveShareApi: (data) => {//优惠劵领取分享信息
        return axios.get('/api/api/mechanism/share_info/0', data);
    },
    couponExtensionApi: (data) => {//优惠券推广记录
        return axios.post('/api/api/coupon/coupon_promote/add_log', data);
    },
}