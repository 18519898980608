/*
* 登陆、首页相关
* */
export default [
    {
        path: 'login',
        name: 'Login',
        meta: {
            title: '登录',
            isShowTab: true,
        },
        component: () => import('@/components/login/Login.vue')
    },
    {
        path: 'home-page',
        name: 'homePage',
        meta: {
            title: '首页',
            isShowTab: true
        },
        component: () => import('@/components/tabbarPage/homePage.vue')
    },
    {
        path: 'login/index',
        name: 'loginIndex',
        meta: {
            title: '',
            isShowTab: false
        },
        component: () => import('@/views/login/index.vue')
    },
]