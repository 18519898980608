/*首页装修
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {

    getHomeModules: (data, type) => {//首页装修模块接口
        if(!!type){
            return axios.get('/api/api/home/decorates/'+ type, data);
        }else{
            return axios.get('/api/api/home/decorates', data);
        }
        
    },

    /*
        news  头条
        live  直播
        coupon 优惠券
        task  打卡
        package 套餐
        activity  活动
        appoint 约见
        camp 训练营
        good 商城
        deposit_lesson 定金课
        video_promotion 免费视频
    */
    getProductsList: (type, data) => {//首页头条列表接口
        return axios.post('/api/api/home/decorates/items/'+ type, data);
    },
    getVideoPromotion: (type, tagId,data) => {//首页视频列表接口
        return axios.post('/api/api/home/decorates/items/'+ type + '?s_tag_id=' + tagId, data);
    },
    getAdvertApi: () => {//首页头条列表接口
        return axios.get('/api/api/home/alert')
    },

    // gettcList: (type, data) => {//首页头条列表接口
    //     return axios.get('api/mechanismapi/join/frontend-list?type=camp', data);
    // },

    postSubscribeLive: (data) => {//首页-直播预约接口
        return axios.post('/api/api/live/subscribe',data)
    },













    refundListApi: (data) => {//退款列表
        return axios.get('/api/api/account/detail/refund/list', data);
    },
    getInvoiceCreate: (data) => {//发票保存接口
        return axios.post('/api/api/invoice/create',data);
    },
    getInvoicePayableDel: (itemId) => {//删除发票抬头
        return axios.iDelete('/api/api/invoice/payable-del/'+itemId);
    },
    putAccountCancel: (accountId) => {//钱包详情页-撤销
        return axios.put('api/api/account/detail/cancel/'+accountId);
    },
    videoTags: () => {//标签列表，无分页
        return axios.get('/api/tools/tags/list/all?type=video_promotion');
    },
}