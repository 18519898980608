export default [
    {
        path: 'oauth',
        name: 'oauth',
        meta: {
            title: '',
            isShowTab: false
        },
        component: () => import('@/components/oauth/oauth.vue')
    },
    {
        path: 'oauth2',
        name: 'oauth2',
        meta: {
            title: '',
            isShowTab: false
        },
        component: () => import('@/components/oauth/oauth2.vue')
    },
]