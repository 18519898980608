/*搜索相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    searchAllApi: (data) => {//搜索全部
        return axios.get('/api/api/home/search/all', data);
    },
    searchData: (data) => {//搜索指定类型
        return axios.get('/api/api/home/search', data);
    },
}