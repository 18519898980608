/*买赠
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getProductInfo: (type,id) => {//产品页基本数据
        return axios.get(`/products/give_product/get_info/type/${type}/id/${id}`);
    },
    getGiftInfo: (order_id) => {//赠送页的数据
        return axios.get(`/products/give_product/share_info/${order_id}`);
    },
    getReceiveInfo: (data) => {//领取页的基本数据
        return axios.get(`/products/give_product/share_receiver`,data);
    },
    postReceive: (data) => {//领取页的领取方法
        return axios.post(`/products/give_product/receiver`,data);
    },
   
   
}