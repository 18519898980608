/*苹果底部适配*/
function isIPhoneX(el = document.body) {
    var u = navigator.userAgent;
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否ios终端
    if (isIOS) {
        if (screen.height == 812 && screen.width == 375) {
            el.style.paddingBottom = '34px';
        }
    }
}
export default isIPhoneX;