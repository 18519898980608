/** 分享素材 */
export default [
    {
        path: 'share/material',
        name: 'share-material',
        meta: {
            title: '分享素材',
        },
        component: () => import('@/views/share/material.vue')
    },

]