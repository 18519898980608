/* 代理相关 */
export default [
    // 合伙人区-视频号生成相关页面
    {
        path: 'agent/videoPromote/list',
        name: 'videoPromote-list',
        meta: {
            title: '视频号推广'
        },
        component: () =>
            import('@/views/agent/videoPromote/list.vue')
    },
    {
        path: 'agent/videoPromote/create',
        name: 'videoPromote-create',
        meta: {
            title: '生成链接'
        },
        component: () =>
            import('@/views/agent/videoPromote/create.vue')
    },
    {
        path: 'agent/visitor/index',
        name: 'visitor-index',
        meta: {
            title: '访客记录'
        },
        component: () =>
            import('@/views/agent/visitor/index.vue')
    },
    {
        path: 'agent/visitor/detail',
        name: 'visitor-detail',
        meta: {
            title: '访客详情'
        },
        component: () =>
            import('@/views/agent/visitor/detail.vue')
    },
    {
        path: 'agent/team/index',
        name: 'team-index',
        meta: {
            title: '我的团队'
        },
        component: () =>
            import('@/views/agent/team/index.vue')
    },
    {
        path: 'agent/team/search',
        name: 'team-search',
        meta: {
            title: '我的团队'
        },
        component: () =>
            import('@/views/agent/team/search.vue')
    },
    {
        path: 'agent/customer/index',
        name: 'customer-index',
        meta: {
            title: '客户管理'
        },
        component: () =>
            import('@/views/agent/customer/index.vue')
    },
    {//我的客户我的求助
        path: 'agent/customer/clients',
        name: 'customer-clients',
        meta: {
            title: '客户管理'
        },
        component: () =>
            import('@/views/agent/customer/clients.vue')
    },
    {//我的客户跟进记录
        path: 'agent/customer/record',
        name: 'customer-record',
        meta: {
            title: '维护记录'
        },
        component: () =>
            import('@/views/agent/customer/record.vue')
    },
    {//我的客户退回记录
        path: 'agent/customer/returnList',
        name: 'customer-returnList',
        meta: {
            title: '退回记录'
        },
        component: () =>
            import('@/views/agent/customer/returnList.vue')
    },
    {//我的客户 金牌
        path: 'agent/customer/consultant',
        name: 'customer-consultant',
        meta: {
            title: '金牌顾问'
        },
        component: () =>
            import('@/views/agent/customer/consultant.vue')
    },
    {//我的客户 确认清单
        path: 'agent/customer/inventory',
        name: 'customer-inventory',
        meta: {
            title: '确认清单'
        },
        component: () =>
            import('@/views/agent/customer/inventory.vue')
    },
    {//我的客户 确认清单
        path: 'agent/customer/searchList',
        name: 'customer-searchList',
        meta: {
            title: '搜索'
        },
        component: () =>
            import('@/views/agent/customer/searchList.vue')
    },
    {//订单查询
        path: 'agent/orderQuery/index',
        name: 'orderQuery-index',
        meta: {
            title: '订单查询'
        },
        component: () =>
            import('@/views/agent/orderQuery/index.vue')
    },
    {//订单查询搜索
        path: 'agent/orderQuery/search',
        name: 'orderQuery-search',
        meta: {
            title: '订单查询'
        },
        component: () =>
            import('@/views/agent/orderQuery/search.vue')
    },
    {//订单查询搜索
        path: 'agent/attribution/search',
        name: 'attribution-search',
        meta: {
            title: '归属查询'
        },
        component: () =>
            import('@/views/agent/attribution/search.vue')
    },
    {// 2021新版代理
        path: 'agent/agentIndex',
        name: 'agentIndex',
        meta: {
            title: ''
        },
        component: () =>
            import ('@/views/agent/index.vue')
    },
    {// 代理权益
        path: 'agent/agentRights',
        name: 'agentRights',
        meta: {
            title: '',
        },
        component: () =>
            import ('@/views/agent/rights.vue')
    },
    {//金牌顾问上传资料
        path: 'agent/adviser/index',
        name: 'adviser-index',
        meta: {
            title: '成为顾问'
        },
        component: () =>
            import('@/views/agent/adviser/index.vue')
    },
    {//金牌顾问
        path: 'agent/adviser/list',
        name: 'adviser-list',
        meta: {
            title: '求助清单'
        },
        component: () =>
            import('@/views/agent/adviser/list.vue')
    },
    {//金牌顾问退回记录
        path: 'agent/adviser/returnList',
        name: 'adviser-returnList',
        meta: {
            title: '退回记录'
        },
        component: () =>
            import('@/views/agent/adviser/returnList.vue')
    },
    {//金牌顾问跟进记录
        path: 'agent/adviser/record',
        name: 'adviser-record',
        meta: {
            title: '维护记录'
        },
        component: () =>
            import('@/views/agent/adviser/record.vue')
    },
    // 合伙人区-我的微信
    {
        path: 'agent/wechat',
        name: 'wechat',
        meta: {
            title: '我的微信'
        },
        component: () =>
            import('@/views/agent/wechat.vue')
    },
    // 合伙人权益-赠票使用-生成兑换码赠好友
    {
        path: 'agent/give/create',
        name: 'give-create',
        meta: {
            title: '赠给好友'
        },
        component: () =>
            import('@/views/agent/give/create.vue')
    },
    // 合伙人权益-赠票使用-兑换码查看
    {
        path: 'agent/give/code',
        name: 'give-code',
        meta: {
            title: '兑换码'
        },
        component: () =>
            import('@/views/agent/give/code.vue')
    },
    // 合伙人权益-赠票使用-领取
    {
        path: 'agent/give/receive',
        name: 'give-receive',
        meta: {
            title: '领取'
        },
        component: () =>
            import('@/views/agent/give/receive.vue')
    },
    // 合伙人权益-赠票使用-使用详情
    {
        path: 'agent/give/use',
        name: 'give-use',
        meta: {
            title: '使用详情'
        },
        component: () =>
            import('@/views/agent/give/use.vue')
    },
     // 合伙人权益-赠票使用-搜索
     {
        path: 'agent/give/search',
        name: 'give-search',
        meta: {
            title: '搜索',
            isTopBar:false,
        },
        component: () =>
            import('@/views/agent/give/search.vue')
    },
      // 合伙人权益-赠票使用-卖给好友
      {
        path: 'agent/give/sale',
        name: 'give-sale',
        meta: {
            title: '卖给好友',
            isTopBar:false,
        },
        component: () =>
            import('@/views/agent/give/sale.vue')
    },
    // 合伙人权益-赠票使用- 测试小程序跳转
    {
        path: 'agent/give/cs',
        name: 'give-cs',
        meta: {
            title: '小程序跳转',
            isTopBar:false,
        },
        component: () =>
            import('@/views/agent/give/cs.vue')
    },
    //优惠劵推广
    {
        path: 'agent/partnerCoupon/index',
        name: 'partnerCouponIndex',
        meta: {
            title: '优惠劵推广'
        },
        component: () =>
            import('@/views/agent/partnerCoupon/index.vue')
    },
    //优惠劵推广领取详情
    {
        path: 'agent/partnerCoupon/couponDetails',
        name: 'partnerCouponCouponDetails',
        meta: {
            title: '领取详情'
        },
        component: () =>
            import('@/views/agent/partnerCoupon/couponDetails.vue')
    },
    //优惠劵推广领取详情搜索
    {
        path: 'agent/partnerCoupon/searchCouponDetails',
        name: 'partnerSearchCouponDetails',
        meta: {
            title: '搜索'
        },
        component: () =>
            import('@/views/agent/partnerCoupon/searchCouponDetails.vue')
    },
    //代理分享素材
    {
        path: 'agent/material',
        name: 'agentShareMaterial',
        meta: {
            title: '分享素材'
        },
        component: () =>
            import('@/views/agent/material.vue')
    },
    {//产品交付率
        path: 'agent/customer/rate',
        name: 'customer-rate',
        meta: {
            title: '客户管理'
        },
        component: () =>
            import('@/views/agent/customer/rate.vue')
    },
    {//交付详情
        path: 'agent/customer/rateDeta',
        name: 'customer-rateDeta',
        meta: {
            title: '交付详情'
        },
        component: () =>
            import('@/views/agent/customer/rateDeta.vue')
    },
    {
        path: 'agent/customer/unbindList',
        name: 'customerUnbindList',
        meta: {
            title: '可能被解绑客户'
        },
        component: () =>
            import('@/views/agent/customer/unbindList.vue')
    },
]