import { getUrlParam } from '@/common/uilt/getUrlParam'
// 分享信息
const share = {
    namespaced: true,
    state: {
        shareId: getUrlParam('share') || getUrlParam('share_id') || getUrlParam('share_uid') || getUrlParam('shareId') || sessionStorage.getItem('shareId') || '',
        entryUrl: location.href || '',
    },
    getters: {
        shareId: state => parseFloat(state.shareId) || state.shareId,
        entryUrl: state => state.entryUrl,
    }
}
export default share 
