/*服务商相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    sellData: (data) => axios.get('/api/api/service/sell', data),//我服务的销售
    costLis: (data) => axios.get('/api/api/service/cost-list', data),//服务金明细-月份明细
    // serviceFee: (data) => axios.get('/api/api/service/service-fee', data),//服务金明细-年份列表
    myBilling: (data) => axios.get('/api/api/service/my-billing',data),//服务金金额统计以及公司信息
    withdrawalList: (data) => axios.get('/api/api/service/withdrawal-list', data),//提现（结算）记录
    withdrawalDetail: (id) => axios.get(`/api/api/service/withdrawal-detail/${id}`),//结算明细
    withdrawalCancel: (id) => axios.put(`/api/api/service/withdrawal-cancel/${id}`),//撤销体现
    serviceBank: (data) => axios.get(`/api/api/service/bank`,data),//获取提现银行卡以及余额
    serviceWithdrawal: (data) => axios.post(`/api/api/service/withdrawal`, data),//服务商-提现
    // calculate: (data) => axios.get(`/api/api/user_withdrawal/calculate`, data),//输入金额请求接口
    calculate: (data) => axios.get(`/api/api/service/calculate`, data),//输入金额请求接口
    withdrawalInfo: () => axios.get(`/api/api/user_withdrawal/withdrawalInfo`),//对公提现获取开票信息
    getSmsToken: (data) => axios.post(`/api/api/get_sms_token`, data),
    getSmsCode: (data) => axios.post(`/api/api/get_sms_code`, data),

    getAmount:()=>axios.get(`/passport/service/amount`),//获取服务金余额和待结算余额
    getList: (data) => axios.get(`/passport/service/amount-detail`, data),//获取服务金明细
    getUnsettledList: (data) => axios.get(`/passport/service/unsettledList`, data),//待结算服务金明细/订单明细
    getDetail: (data) => axios.get(`/passport/service/amountDetailById`, data),//明细详情
    cancelWithdraw: (data) => axios.post(`/passport/service/withdraw-cancel`, data),//明细详情

}