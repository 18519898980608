
<template>
  <div v-show="show" class="timeout font26">
    <van-empty image="network" description="网络请求失败，请检查您的网络" />
    <div @click="reloadBtn" class="refresh">重新加载</div>
  </div>
</template>

<script>
import { Empty } from "vant";
// 空状态组件
export default {
  name: "timeout",
  components: {
    [Empty.name]: Empty,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    reloadBtn() {
      window.location.reload();
    },
  },
};
</script>

<style  scoped>
.timeout {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 9999;
  overflow: hidden;
}
.timeout_p {
  margin-top: 10px;
  color: #909399;
}
.timeout_img {
  width: 360px;
  height: 310px;
}
.refresh {
  width: 160px;
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
  border: 1px solid #969799;
  color: #969799;
  text-align: center;
}
</style>