/*机构相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getAboutInfo: (group_id) => {//机构关于我们的信息
        return axios.requestGet('/v1/mechanisms/desc/'+group_id);
    },
    
}