/* 资料相关 */
/* keepAlive:该页面是否缓存 */
export default [
  {
    path: 'data/list',
    name: 'dataList',
    meta: {
      title: '资料库',
    },
    component: () => import('@/views/data/list.vue')
  },
  {
    path: 'data/detail',
    name: 'dataDetail',
    meta: {
      title: '资料详情',
    },
    component: () => import('@/views/data/detail.vue')
  },
]